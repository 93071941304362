import Marquee from "react-marquee-slider";

function Testimonail() {
  return (
    <>
      {/* for desktop */}
      <div className="hidden md:block">
        <Marquee velocity={20} direction="ltr">
          <img src="/images/home/band1.png" alt="band1" className="h-[50px]" />
          <img src="/images/home/band1.png" alt="band1" className="h-[50px]" />
        </Marquee>

        <div className="h-[50px]">&nbsp;</div>

        <Marquee velocity={20}>
          <img src="/images/home/band1.png" alt="band1" className="h-[50px]" />
          <img src="/images/home/band1.png" alt="band1" className="h-[50px]" />
        </Marquee>
      </div>

      {/* for mobile */}

      <div className=" md:hidden">
        <Marquee velocity={5} direction="ltr">
          <img
            src="/images/home/band_mob.webp"
            alt="band1"
            className="h-[30px] ml-6"
          />
          <img
            src="/images/home/band_mob.webp"
            alt="band1"
            className="h-[30px] ml-6"
          />
        </Marquee>

        <div className="h-[25px]">&nbsp;</div>

        <Marquee velocity={5}>
          <img
            src="/images/home/band_mob.webp"
            alt="band1"
            className="h-[30px] ml-6"
          />
          <img
            src="/images/home/band_mob.webp"
            alt="band1"
            className="h-[30px] ml-6"
          />
        </Marquee>
      </div>
    </>
  );
}

export default Testimonail;
