function PartnerShip() {
  return (
    <div className="w-[88%] md:w-[80%] mb-[62px] md:mb-[126px] flex-col md:flex-row mx-auto p-6 md:p-[34px] gap-[35px] md:gap-0 flex md:h-[484px] justify-between rounded-[20px] bg-white border-[#E7E7E7]">
      <div className="md:px-[28px] w-full md:w-[40%] flex items-center">
        <h2
          className="RoobertMediumBody text-[35px] md:text-[48px]"
          style={{ lineHeight: "100%" }}
        >
          Unlocking success through strategic partnerships
        </h2>
      </div>
      <div className="w-full md:w-[57%] md:px-[26px] flex items-center">
        <img
          src="images/home/partnership/part_web.webp"
          alt="Partnership"
          className="hidden md:block"
        />
        <img
          src="images/home/partnership/part_mob.webp"
          alt="Partnership"
          className="md:hidden"
        />
      </div>
    </div>
  );
}

export default PartnerShip;
