import { useState, useEffect } from "react";

function GenText1() {
  return (
    <>
      <div>
        OneByZero helps enterprises develop generative AI applications for
        use-cases ranging from enterprise search, employee productivity,
        marketing and customer engagement.
      </div>
      <div className="mt-[20px]">
        {" "}
        We can train, fine-tune and deploy deep learning models, including
        generative AI models. We have deep skills and experience in Natural
        Language Processing (NLP), and in tasks like search, summarization,
        question-answering, content generation, chatbots, workflow automation
        and self-service BI.{" "}
      </div>
    </>
  );
}

function GenText2() {
  return (
    <>
      <div>
        Deep expertise in Large Language Models (LLMs), both in open-source LLMs
        as well as in LLMs available as APIs from major vendors. We can help you
        pick the right model for your specific use-case.
      </div>
      <div className="mt-[20px]">
        {" "}
        Experience in model selection, fine-tuning, prompt engineering, model
        distillation, model evaluation and continuous improvement of models with
        user-feedback.
      </div>
    </>
  );
}

function TextContainer({ text1, text2, optional = "none" }) {
  const [screenSize, setScreenSize] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenSize(window.innerWidth);
    };

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [screenSize]);

  const [lineGap, setLineGap] = useState(110);

  useEffect(() => {
    const WidthAfterRemovingPadding = screenSize - 19 * 2;
    setLineGap(WidthAfterRemovingPadding / 11);
  }, [screenSize]);

  console.log("lineGap", lineGap);

  return (
    <div>
      <div>&nbsp;</div>
      <div className="w-[87%] md:w-[100%] mt-[40px] md:mt-[66px] mb-[70px] md:mb-[126px] gap-[98px] md:gap-0 flex flex-col md:flex-row mx-auto text-[#000] text-[18px]">
        <div
          className={`RoobertRegular leading-[120%] md:ml-[10%]`}
          style={{ width: `${screenSize > 768 ? `${lineGap * 3}px` : ""}` }}
        >
          {/* {text1} */}
          {optional === "generative-ai" ? <GenText1 /> : <>{text1}</>}
        </div>
        <div
          className={` RoobertRegular leading-[120%]`}
          style={{
            width: `${
              screenSize > 768
                ? optional === "hoots"
                  ? `${lineGap * 3}px`
                  : `${lineGap * 3}px`
                : ""
            }`,
            marginLeft: `${screenSize > 768 ? `${lineGap}px` : ""}`,
          }}
        >
          {optional === "generative-ai" ? <GenText2 /> : <>{text2}</>}
        </div>
        <a
          href="/contact-us"
          style={{
            marginLeft: `${screenSize > 768 ? `${lineGap - 12}px` : ""}`,
          }}
          className={`${optional !== "home" ? "hidden" : ""}`}
        >
          <button className="h-fit flex group py-[5px] md:py-[10px] px-2 md:px-4 rounded-md items-center justify-center focus:bg-black focus:text-[#FFF]">
            <div className="RoobertRegular underline underline-offset-[5px] group-focus:no-underline hover:decoration-[3px] items-center">
              Contact Sales
            </div>

            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="17"
              height="17"
              viewBox="0 0 17 17"
              fill="none"
              className="hidden md:flex ml-[10px] group-focus:hidden"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M4.1816 12.8204C3.98634 12.6251 3.98634 12.3085 4.1816 12.1133L11.8281 4.4668L6.80182 4.4668C6.52568 4.4668 6.30182 4.24294 6.30182 3.9668C6.30182 3.69065 6.52568 3.4668 6.80182 3.4668L13.0352 3.4668C13.1678 3.4668 13.2949 3.51948 13.3887 3.61324C13.4825 3.70701 13.5352 3.83419 13.5352 3.9668L13.5352 10.2001C13.5352 10.4763 13.3113 10.7001 13.0352 10.7001C12.759 10.7001 12.5352 10.4763 12.5352 10.2001V5.17391L4.88871 12.8204C4.69345 13.0156 4.37687 13.0156 4.1816 12.8204Z"
                fill="black"
              />
            </svg>

            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="17"
              height="17"
              viewBox="0 0 17 17"
              fill="none"
              className="hidden group-focus:block ml-[10px]"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M4.1816 12.8204C3.98634 12.6251 3.98634 12.3085 4.1816 12.1133L11.8281 4.4668L6.80182 4.4668C6.52568 4.4668 6.30182 4.24294 6.30182 3.9668C6.30182 3.69065 6.52568 3.4668 6.80182 3.4668L13.0352 3.4668C13.1678 3.4668 13.2949 3.51948 13.3887 3.61324C13.4825 3.70701 13.5352 3.83419 13.5352 3.9668L13.5352 10.2001C13.5352 10.4763 13.3113 10.7001 13.0352 10.7001C12.759 10.7001 12.5352 10.4763 12.5352 10.2001V5.17391L4.88871 12.8204C4.69345 13.0156 4.37687 13.0156 4.1816 12.8204Z"
                fill="white"
              />
            </svg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="17"
              height="17"
              viewBox="0 0 17 17"
              fill="none"
              className="md:hidden ml-[10px] group-focus:hidden"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M4.1777 12.8201C3.98243 12.6249 3.98243 12.3083 4.1777 12.113L11.8241 4.46655L6.79792 4.46655C6.52178 4.46655 6.29792 4.2427 6.29792 3.96655C6.29792 3.69041 6.52178 3.46655 6.79792 3.46655L13.0313 3.46655C13.1639 3.46655 13.291 3.51923 13.3848 3.613C13.4786 3.70677 13.5312 3.83394 13.5313 3.96655L13.5312 10.1999C13.5312 10.476 13.3074 10.6999 13.0312 10.6999C12.7551 10.6999 12.5312 10.476 12.5312 10.1999V5.17366L4.8848 12.8201C4.68954 13.0154 4.37296 13.0154 4.1777 12.8201Z"
                fill="black"
              />
            </svg>
          </button>
        </a>
      </div>
      <div>&nbsp;</div>
    </div>
  );
}

export default TextContainer;
