export default function FilteredButton({
  selected,
  text,
  color,
  index,
  setSelectedFilterState,
}) {
  return (
    <button
      className={`py-[7px] px-[12px] flex items-center ${
        !selected
          ? "boder-[1px] border border-[#3E3E3E] bg-[#FAF7F7]"
          : `${color}`
      }  rounded-[100px] uppercase tracking-[0.845px]`}
      onClick={(e) => setSelectedFilterState(e, index, true)}
    >
      <p className="text-[#000] RoobertRegular">{text}</p>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="15"
        height="15"
        viewBox="0 0 15 15"
        fill="none"
        className={`ml-[10px] ${!selected ? "hidden" : ""}`}
        onClick={(e) => setSelectedFilterState(e, index, false)}
      >
        <g opacity="0.6">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M11.7785 4.03139C12.003 3.80684 12.003 3.44277 11.7785 3.21822C11.5539 2.99367 11.1898 2.99367 10.9653 3.21822L7.49687 6.68663L4.02846 3.21822C3.80391 2.99367 3.43984 2.99367 3.21529 3.21822C2.99074 3.44277 2.99074 3.80684 3.21529 4.03139L6.6837 7.4998L3.21529 10.9682C2.99074 11.1928 2.99074 11.5568 3.21529 11.7814C3.43984 12.0059 3.80391 12.0059 4.02846 11.7814L7.49688 8.31298L10.9653 11.7814C11.1898 12.0059 11.5539 12.0059 11.7785 11.7814C12.003 11.5568 12.003 11.1928 11.7785 10.9682L8.31005 7.4998L11.7785 4.03139Z"
            fill="black"
          />
        </g>
      </svg>
    </button>
  );
}
