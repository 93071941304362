function Enterprise({ data, optional = "none" }) {
  const list = data.map((item, index) => {
    return (
      <div
        key={index}
        className={`flex justify-between ${
          index % 2 === 0 ? "md:flex-row" : "md:flex-row-reverse"
        } flex-col items-start px-3 md:px-0 gap-6 md:gap-0 `}
      >
        <div className="w-full md:w-[33.33%] flex flex-col gap-6 items-start shrink-0">
          <h2 className="text-[24px] md:text-[35px] text-[#FFF] leading-[120%] RoobertMedium">
            {item.title}
          </h2>
          <div className="text-[18px] text-[#FFF] leading-[120%] RoobertRegular">
            {item.description}
          </div>
        </div>

        <div className="md:hidden w-full md:w-[55.6%] rounded-[18px] shrink-0">
          <img src={item.img_mob} alt={item.title} className="rounded-[18px]" />
        </div>

        <div className="w-full hidden md:block md:w-[55.6%] rounded-[18px] shrink-0">
          <img src={item.img} alt={item.title} />
        </div>
      </div>
    );
  });

  return (
    <div>
      <div>&nbsp;</div>
      {optional === "story" ? (
        <></>
      ) : (
        <div className="mt-[76px] md:mt-[83px] ml-[6%] md:ml-[9.8%] text-[48px] RoobertMedium text-[#EDEEF3]">
          Enterprise AI. Redefined.
        </div>
      )}

      <div className="w-[87%] md:w-[80%] mb-[45px] md:mb-[80px] mx-auto mt-[150px] md:mt-[121px] flex flex-col items-end gap-[75px] md:gap-[204px]">
        {list}
      </div>
      <div>&nbsp;</div>
    </div>
  );
}

export default Enterprise;
