function AboutMobile({ title, description, data }) {
  const list = data.map((item, index) => {
    return (
      <div className="about-mobile px-[13px] pt-[12px] pb-[28px]">
        <div className="bg-[#F8F6E0]">
          <img src={item.img} />
        </div>
        <div className="mt-6 px-[13px]">
          <div className="RoobertMedium tracking-[-0.4px] text-[20px] leading-[120%] text-[#FAF7F7]">
            {item.title}
          </div>
          <div className="RoobertRegular mt-[10px] leading-[120%] text-[#FAF7F7] opacity-80 ">
            {item.description}
          </div>
        </div>
      </div>
    );
  });
  return (
    <div className="w-[86%] mx-auto md:hidden">
      <div>&nbsp;</div>

      <div className=" text-white mx-auto mt-[70px] ">
        <div className=" text-[35px] sm:text-[48px] RoobertMedium leading-[24px] sm:leading-[48px]">
          {title}
        </div>
        <div className="sm:w-[29%] mt-[20px] sm:mt-2  text-[18px] RoobertLight leading-[120%]">
          {description}
        </div>
      </div>
      <div className="flex flex-col mb-[40px] mt-[50px] gap-[30px]">{list}</div>
      <div>&nbsp;</div>
    </div>
  );
}

export default AboutMobile;
