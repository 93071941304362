import Header from "./shared/header";
import TextContainer from "./shared/textContainer";
import Strategy from "./shared/strategy";

import Deployement from "./shared/deployement";
import WhitePaper from "./shared/whitepaper";
import Contact from "./shared/contact";
import Footer from "./shared/footer";
import HeadingSubHeadingContainer from "./shared/headingSubHeadingContainer";
import Slider from "./shared/slider";
import DesktopLayout from "./shared/desktop_layout";
import MobileLayout from "./shared/mobile_layout";
import { useState, useEffect } from "react";
import MobileHero from "./shared/mobile_hero";
import DesktopHero from "./shared/desktop_hero";

import HeroBackground from "./shared/hero_background";

export const sliderData = [
  {
    img: "/images/generative-ai/Carsouel/Enterprise.webp",
    img_mob: "/images/generative-ai/Carsouel/Enterprise_mob.webp",
    title: "Enterprise Search",
    description:
      "Natural language search and question-answering on wide swathes of enterprise data, such as file repositories, content management systems, chat platforms, code repositories, BI tools, data glossaries and catalogs, etc.",
  },
  {
    img: "/images/generative-ai/Carsouel/Consumable.webp",
    img_mob: "/images/generative-ai/Carsouel/Consumable_mob.webp",
    title: "Consumable Data & Insights",
    description:
      "Simplify the management, governance, query and usage of structured enterprise datasets. This also includes use of generative AI for automated generation of glossaries, catalogs, quality checks and tests.",
  },
  {
    img: "/images/generative-ai/Carsouel/Customer.webp",
    img_mob: "/images/generative-ai/Carsouel/Customer_mob.webp",
    title: "Customer Engagement",
    description:
      "Simplify the management, governance, query and usage of structured enterprise datasets. This also includes use of generative AI for automated generation of glossaries, catalogs, quality checks and tests. ",
  },
  {
    img: "/images/generative-ai/Carsouel/Next.webp",
    img_mob: "/images/generative-ai/Carsouel/Next_mob.webp",
    title: "Next-gen Marketing",
    description:
      "Automated generation of spiels, creatives and other content and configurations for both broad-based and personalized marketing campaigns.",
  },
  {
    img: "/images/generative-ai/Carsouel/productivity.webp",
    img_mob: "/images/generative-ai/Carsouel/productivity_mob.webp",
    title: "Productivity Boosters",
    description:
      "For developers and IT staff, generate specific kinds of code that meet enterprise guidelines, as well as generate test cases in an automated manner. Simplify orchestration of workflows across different enterprise APIs via agents, aiding employees in tasks around HR, CRM, reporting, project management and collaboration. ",
  },
];

const strategyDataMobile = [
  {
    img: "images/analytics-and-ml/platform.webp",
    title: "Hundred Foot Overview",
    description:
      "Help enterprises navigate the generative AI buzz and chart out a strategy, including use-cases, initiatives and processes for optimizing ROI.",
  },
  {
    img: "images/analytics-and-ml/bird.webp",
    title: "Establish Frameworks for Development",
    description:
      "Establish frameworks for picking the right foundation models, evaluating them, finetuning them engineering prompts, optimizing performance, improving with feedback, defining guardrails on their use, and setting clear expectations on their capabilities.",
  },
  {
    img: "images/analytics-and-ml/insights.webp",
    title: "Deployment and Beyond",
    description:
      "Establish LLMOps, including traditional MLOps as well as specific activities related to LLMs including gathering and use of feedback for RLHF (Reinforcement Learning with Human Feedback), and continuous evaluation of new foundation models to test their performance.",
  },
];

const strategyDataWeb = [
  {
    img: "images/generative-ai/strategy/img1.png",
    title: "Gen AI Strategy",
    description:
      "Help enterprises navigate the generative AI buzz and chart out a strategy, including use-cases, initiatives and processes for optimizing ROI.",
  },
  {
    img: "images/generative-ai/strategy/img2.png",
    title: "Establish Frameworks for Development",
    description:
      "Establish frameworks for picking the right foundation models, evaluating them, finetuning them engineering prompts, optimizing performance, improving with feedback, defining guardrails on their use, and setting clear expectations on their capabilities.",
  },
  {
    img: "images/generative-ai/strategy/img3.png",
    title: "Deployment and Beyond",
    description:
      "Establish LLMOps, including traditional MLOps as well as specific activities related to LLMs including gathering and use of feedback for RLHF (Reinforcement Learning with Human Feedback), and continuous evaluation of new foundation models to test their performance. ",
  },
];

const carouselData = [
  {
    title: "Powerful Searches",
    description:
      "We established a domain-specific search platform for a gaming company that supported heterogeneous data sources, specialized vocabulary and continuous streams of new content. This resulted in a 50% increase in recall. ",
  },
  {
    title: "Increased User Satisfaction",
    description:
      "We finetuned LLMs and deployed variety of NLP and ranking models for a gaming company to optimize results of queries. This resulted in a 40% increase in precision of results shown in 8 weeks, resulting in better user satisfaction and higher engagement.",
  },
];

const WhitePapercardData = [
  {
    img: "images/analytics-and-ml/90x.webp",
    svg: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="33"
        viewBox="0 0 32 33"
        fill="none"
      >
        <g opacity="0.5">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M16.0076 2.26074C8.20455 2.26074 1.87891 8.58639 1.87891 16.3895C1.87891 24.1925 8.20455 30.5182 16.0076 30.5182C23.8106 30.5182 30.1363 24.1925 30.1363 16.3895C30.1363 8.58639 23.8106 2.26074 16.0076 2.26074ZM3.90557 16.3895C3.90557 9.70569 9.32385 4.28741 16.0076 4.28741C22.6913 4.28741 28.1097 9.70569 28.1097 16.3895C28.1097 23.0732 22.6913 28.4915 16.0076 28.4915C9.32385 28.4915 3.90557 23.0732 3.90557 16.3895ZM10.7504 20.0461C10.4134 19.563 9.74851 19.4445 9.26534 19.7815C8.78216 20.1185 8.66368 20.7834 9.00071 21.2666C10.5409 23.4747 13.1032 24.923 16.0039 24.923C18.9046 24.923 21.4669 23.4747 23.0071 21.2666C23.3441 20.7834 23.2256 20.1185 22.7425 19.7815C22.2593 19.4445 21.5944 19.563 21.2574 20.0461C20.099 21.7068 18.1779 22.7897 16.0039 22.7897C13.83 22.7897 11.9088 21.7068 10.7504 20.0461ZM11.4672 15.0032C12.4982 15.0032 13.3339 14.1673 13.3339 13.1366C13.3339 12.1058 12.4982 11.2699 11.4672 11.2699C10.4363 11.2699 9.60057 12.1058 9.60057 13.1366C9.60057 14.1673 10.4363 15.0032 11.4672 15.0032ZM22.4006 13.1366C22.4006 14.1673 21.5648 15.0032 20.5339 15.0032C19.503 15.0032 18.6672 14.1673 18.6672 13.1366C18.6672 12.1058 19.503 11.2699 20.5339 11.2699C21.5648 11.2699 22.4006 12.1058 22.4006 13.1366Z"
            fill="black"
          />
        </g>
      </svg>
    ),
    title: "Increase Response Accuracy",
    description:
      "Owltalk achieved a 90% increase in response accuracy while engaging in over 15,000 conversations per month.",
  },
];

const deployementData = [
  {
    title: "Content Generation",
    description:
      "Hyper-personalized content generation, including text and images, for marketing and customer engagement use-cases.",
    img: "images/generative-ai/Deployement/deployement-1.webp",
    img_mob: "images/generative-ai/Deployement/deployement-1_mob.webp",
  },
  {
    title: "Data Stories",
    description:
      "Generation of data stories from structured and unstructured datasets, including video data stories",
    img: "images/generative-ai/Deployement/deployement-2.webp",
    img_mob: "images/generative-ai/Deployement/deployement-2_mob.webp",
  },
  {
    title: "Data Ingestion for Gen AI",
    description:
      "Collection, indexing and serving of data for enterprise search systems",
    img: "images/generative-ai/Deployement/deployement-3.webp",
    img_mob: "images/generative-ai/Deployement/deployement-3_mob.webp",
  },
];

function GenerativeAI() {
  const [screenSize, setScreenSize] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenSize(window.innerWidth);
    };

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const Layout = ({ children, backgroundColor }) => {
    return screenSize <= 768 ? (
      <MobileLayout backgroundColor={backgroundColor}>{children}</MobileLayout>
    ) : (
      <DesktopLayout backgroundColor={backgroundColor}>
        {children}
      </DesktopLayout>
    );
  };

  return (
    <div>
      <DesktopHero
        heroImage={"images/generative-ai/hero.webp"}
        heading=" Build custom generative AI applications for your enterprise using
        your data"
        title={"GENERATIVE AI"}
        backgroundColor="sky-blue"
        name="generative-ai"
      />

      <MobileHero
        heading={
          "Build custom generative AI applications for your enterprise using your data"
        }
        title={"GENERATIVE AI"}
        backgroundColor="sky-blue"
      />

      <Layout>
        <div className=" sm:hidden w-[88%] mx-auto">
          <div>&nbsp;</div>
          <img
            src="/images/generative-ai/generative_ai_hero_mob.webp"
            alt="frame1"
            className=" mt-[40px]"
          />
        </div>
      </Layout>

      <Layout>
        <TextContainer
          text1="OneByZero helps enterprises develop, train, fine-tune and deploy deep learning models, including generative AI models. We especially have deep skills and experience in Natural Language Processing (NLP), and in tasks like search, summarization, question-answering, content generation, chatbots, workflow automation and self-service BI."
          text2="Deep expertise in Large Language Models (LLMs), both in open-source LLMs as well as in LLMs available as APIs from major vendors.
            Experience in model selection, model distillation, fine-tuning, prompt engineering, domain-based adaptation, RLHF and continuous improvement of models with user-feedback."
          optional="generative-ai"
        />
      </Layout>

      <Layout backgroundColor="black">
        <Strategy
          title={"Why work with OneByZero to define your AI strategy?"}
          description={
            "OnebyZero has deep experience in Generative AI and deep learning. We keep ourselves up-to-date on the latest in the world of LLMs, chains, agents, platforms and use-cases so that you don’t have to. And we have experience in going from simple demos to all the challenges around going live. Furthermore, we have our own Reference Architecture for generative AI applications that relies on a combination of open source and proprietary software and LLMs."
          }
          img="images/generative-ai/strategy.webp"
          dataMobile={strategyDataMobile}
          dataWeb={strategyDataWeb}
          optional="generative-ai"
        />
      </Layout>

      <Layout>
        <div>
          {" "}
          <div>&nbsp;</div>
          <HeadingSubHeadingContainer
            heading="Embrace Generative AI from strategy to production"
            subHeading="OneByZero helps enterprises navigate the deluge of information and chatter on generative AI, and chart out a generative AI strategy, including picking impactful use-cases and delivering them."
          />
          <Slider data={sliderData} swiperHeight={640} />
        </div>
      </Layout>

      <Layout backgroundColor="black">
        <Deployement
          title={"Accelerated Deployment"}
          description={
            "OnebyZero has several accelerators to speed up the deployment of use-cases."
          }
          data={deployementData}
        />
      </Layout>

      <Layout>
        <WhitePaper
          heading={"Meet your business goals with Generative AI"}
          subHeading={"Welcome to the future of business."}
          title={"Go Deeper with Generative AI"}
          description={
            "Leverage the power of Generative AI to get measurable results."
          }
          // cardData={WhitePapercardData}
          carouselData={carouselData}
        />
      </Layout>

      <Layout backgroundColor="black">
        <Contact
          title={
            "See how Generative AI can revolutionize your business, and improve customer engagement and employee productivity"
          }
          type="service"
        />
        <Footer />
      </Layout>
    </div>
  );
}

export default GenerativeAI;
