const Card = ({ img, title, description, dataLength }) => {
  return (
    <div
      className={`about-mobile px-[13px] pt-[12px] pb-[28px] ${
        dataLength % 2 === 0 ? "flex-grow" : ""
      }  flex flex-col`}
    >
      <div className=" flex-grow rounded-[8px]">
        <img src={img} />
      </div>
      <div className="mt-6 px-[13px]">
        <div className="RoobertMedium w-[90%] tracking-[-0.4px] text-[20px] leading-[120%] text-[#FAF7F7]">
          {title}
        </div>
        <div className="RoobertRegular w-[80%] mt-[10px] leading-[120%] text-[#FAF7F7] opacity-80 ">
          {description}
        </div>
      </div>
    </div>
  );
};

function About({ title, description, img1, img2, img3, img4, data }) {
  let width1 = 44;
  let width2 = 54.5;

  const name = title.split(" ")[1].toLowerCase().slice(0, -1);
  console.log("name", name);

  if (name === "kuwago" || name === "perch") {
    width1 = 55.5;
    width2 = 43;
  }

  //console.logg("")

  const dataLength = data.length;
  console.log("dataLength ", dataLength);

  const count = Math.floor(dataLength / 2);
  const firstPart = data.slice(0, count);
  const secondPart = data.slice(count, dataLength);

  console.log("firstPart ", firstPart);
  console.log("secondPart ", secondPart);

  return (
    <div className="hidden md:block">
      <div>&nbsp;</div>

      <div className="w-[87%] sm:w-[80%] text-white mx-auto mt-[70px] sm:mt-[94px]">
        <div className=" text-[35px] sm:text-[48px] RoobertMedium leading-[24px] sm:leading-[48px]">
          {title}
        </div>

        <div
          className={`sm:w-[29%] ${
            name === "pique" ? "hidden" : "block"
          } mt-[20px] sm:mt-5 RoobertRegular text-[18px] leading-[120%]`}
        >
          {description}
        </div>

        <div
          className={`sm:w-[55%] ${
            name === "pique" ? "block" : "hidden"
          } mt-3 sm:mt-6 text-[18px] RoobertLight leading-[120%]`}
        >
          <div className="RoobertRegular">
            Most enterprises, today, struggle to personalize user experience.{" "}
          </div>
          <div className="mt-[10px] RoobertRegular">
            Pique is a hyper-personalized customer engagement engine for your
            digital platforms, with the goal of improving your customers in-app
            experience and driving engagement. It comes with an extensive list
            of use-cases for the banking and telecommunications domains.{" "}
          </div>
          <div className="mt-[10px] RoobertRegular">
            These use-cases span a variety of greetings, cross-sell and upsell
            opportunities, and insight categories that reveal interesting
            findings based on the customer positions and behavioral patterns. 
          </div>
        </div>
      </div>

      <div className="w-[78%] justify-between flex flex-row mx-auto mt-[100px] mb-[71px]">
        <div
          className={`w-[${width1}%] flex flex-col gap-[18px]`}
          style={{ width: `${width1}%` }}
        >
          {firstPart.map((item) => {
            return (
              <Card
                img={item.img_web}
                title={item.title}
                description={item.description}
                dataLength={dataLength}
              />
            );
          })}
        </div>
        <div
          className={`w-[${width2}%] flex flex-col gap-[18px]`}
          style={{ width: `${width2}%` }}
        >
          {secondPart.map((item) => {
            return (
              <Card
                img={item.img_web}
                title={item.title}
                description={item.description}
                dataLength={dataLength}
              />
            );
          })}
        </div>
      </div>

      <div>&nbsp;</div>
    </div>
  );
}

export default About;
