import React, { useState, useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const HorizontalCarouselII = ({ data }) => {
  const [index, setIndex] = useState(0);

  // console.log(data);
  const sliderRef = useRef(null);

  const goToSlide = (slideIndex) => {
    //console.log("slideIndex ", slideIndex);
    sliderRef.current.slickGoTo(slideIndex);
  };

  const CustomNextArrow = (props) => {
    const { style, onClick } = props;
    return (
      <button
        className=" invisible sm:visible top-[42%] right-[4%] absolute"
        style={{
          ...style,
        }}
        onClick={() => {
          setIndex((index + 1) % 5);
          onClick();
        }}
      >
        {/* Your custom next arrow content */}
        <img src="images/common/arrow.svg" alt="arrow" />
      </button>
    );
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 1.038,
    initialSlide: 0,
    slidesToScroll: 1,
    //centerMode: true,
    // centerPadding: "60px",
    // prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
    arrows: true,
    autoplay: true, // Enable autoplay
    autoplaySpeed: 2500, // Set the autoplay interval to 5000 milliseconds (5 seconds)
    //afterChange: handleSlideChange,
    beforeChange: (currentSlide, nextSlide) => {
      setIndex(nextSlide); // Update the circle state before the slide changes
    },
  };

  const list = data.map((item, index) => {
    return (
      <div key={index * 2}>
        <div className="hidden pr-[17px] md:pr-[9px] 2xl:pr-[5px] md:block py-0 sm:py-9 h-[90%]">
          <img
            src={item.img}
            alt="frame4"
            className=" md:pl-[25px] 2xl:pl-[29px] h-full w-full"
          />
        </div>

        <div className="md:hidden px-[10px] py-0 sm:py-9 h-[90%]">
          <img src={item.img_mob} alt="frame4" className="h-full w-full" />
        </div>
      </div>
    );
  });

  const circles = data.map((item, idx) => {
    return idx === index ? (
      <button
        key={idx}
        aria-label={idx}
        className="h-[5px] sm:h-[10px] w-[5px] sm:w-[10px] bg-black rounded-[50px] sm:rounded-[100px] block"
        onClick={() => goToSlide(idx)}
      ></button>
    ) : (
      <button
        key={idx}
        aria-label={idx}
        className="h-[5px] sm:h-[10px] w-[5px] sm:w-[10px] border-[#000000] border-[0.3px] sm:border-[0.6px] rounded-[50px] sm:rounded-[100px] block"
        onClick={() => goToSlide(idx)}
      ></button>
    );
  });

  return (
    <div className="bg-white sm:pt-[30px] flex rounded-[5px]">
      <div className="w-full">
        <Slider ref={sliderRef} {...settings}>
          {list}
        </Slider>
        <div className=" hidden lg:flex justify-center gap-2 sm:gap-4  pb-5 px-[0px] sm:px-[10px]">
          {circles}
        </div>
      </div>
    </div>
  );
};

export default HorizontalCarouselII;
