export default function CardBody({
  imgBackground,
  img_web,
  img_mob,
  title,
  description,
  tag,
  inventionList,
  numberList,
  index,
}) {
  const invList = inventionList.map((item, index) => {
    return (
      <li key={index} className="text-[#000] RoobertRegular">
        {item.description}
      </li>
    );
  });

  const numList = numberList.map((item, index) => {
    return (
      <div key={index} className="flex flex-col items-center">
        {item.num === "" ? (
          <></>
        ) : (
          <div className="text-center text-[52px] text-[#000] RoobertMedium leading-[120%] ">
            {item.num}
          </div>
        )}

        <div className="mt-[6px] text-center text-[#000] RoobertRegular">
          {item.description}
        </div>
      </div>
    );
  });

  return (
    <div>
      <div className="flex md:flex-row flex-col">
        <div className={` hidden md:flex w-[60%] max-w-[275px] min-w-[225px] `}>
          <img src={img_web} alt="dbs" className="w-full h-[261px] " />
        </div>

        <div className="flex md:hidden">
          <img src={img_mob} alt="dbs" className="h-[90px] w-[95px]" />
          <div className=" text-[25px] md:text-[35px] ml-[19px] leading-[120%] text-[#000] RoobertMedium">
            {title}
          </div>
        </div>

        <div className=" md:ml-[19px]">
          <div className="hidden md:block text-[25px] md:text-[35px] leading-[120%] text-[#000] RoobertMedium">
            {title}
          </div>
          <div className="mt-[19px] bg-[#F3EAFF] py-[5px] px-[8px] flex items-center justify-center uppercase md:min-w-[135px] md: max-w-[135px] w-fit md:w-[23%] text-[9px] text-[#000] RoobertLight tracking-[1.845px] rounded-[4px]">
            {tag}
          </div>
          <div className="mt-[19px] leading-[120%] text-[#000] RoobertRegular md:w-[55%] md:min-w-[300px]">
            {description}
          </div>
        </div>
      </div>

      <div className="py-[20px] pl-[12px] pr-[12px] md:pr-[62px] mt-[25px] flex md:flex-row flex-col gap-[60px]">
        <div className="md:w-[40%]">
          <div className=" uppercase text-[12px] text-[#000] RoobertRegular tracking-[2.46px]">
            Our Intervention
          </div>
          <div className=" mt-[36px] text-[16px]">
            <ul className="list-decimal ml-4 flex flex-col gap-[20px]">
              {invList}
            </ul>
          </div>
        </div>
        <div className="w-[1px] hidden md:block bg-[#F0F0F0]"></div>
        <div className="md:w-[50%]">
          <div className=" uppercase text-[12px] tracking-[2.46px] text-[#000] RoobertRegular">
            Business OUtcomes
          </div>
          <div className="mt-[36px] grid grid-cols-1 md:grid-cols-2 gap-x-[78px] gap-y-[40px]">
            {numList}
          </div>
        </div>
      </div>
    </div>
  );
}
