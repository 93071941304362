import TextContainer from "./shared/textContainer";

import About from "./shared/about";
import WhitePaper from "./shared/whitepaper";
import Contact from "./shared/contact";
import Footer from "./shared/footer";
import MobileLayout from "./shared/mobile_layout";
import { useState, useEffect } from "react";
import MobileHero from "./shared/mobile_hero";
import DesktopLayout from "./shared/desktop_layout";
import DesktopHero from "./shared/desktop_hero";
import AboutMobile from "./shared/about_mobile";
import HeadingSubHeadingContainer from "./shared/headingSubHeadingContainer";
import Slider from "./shared/slider";

function Perch() {
  const carouselData = [
    {
      title: "Banking Customer 360-degree view",
      description:
        "A banking customer was able to consolidate customer data from account activity, transaction, deposit certificate, demographics and other data sets to create a unified view of a customer for churn-prevention and price-sensitivity analysis.",
    },
    {
      title: "Telco Customer 360-degree view",
      description:
        "The customer value management department of a telecommunications company was able to combine usage, app and USSD activity, purchase, topup and location datasets to create a holistic view of a customer for next-best-offer use-cases.",
    },
  ];

  const aboutData = [
    {
      img: "images/perch/About/mobile/img1.webp",
      img_web: "images/perch/About/web/360.webp",
      title: "Single 360-degree view of customer",
      description:
        "A customer data platform to bring together a single view of your customer, stitched together from 10s of data sources. (80+ connectors)",
    },

    {
      img: "images/perch/About/mobile/img3.webp",
      img_web: "images/perch/About/web/logic.webp",
      title: "Customizable Aggregation Logic",
      description:
        "Customizable logic to derive and combine customer features from different sources in a consistent and reusable fashion",
    },

    {
      img: "images/perch/About/mobile/img2.webp",
      img_web: "images/perch/About/web/industry.webp",
      title: "Industry Specific Models",
      description:
        "Several industry-specific customer data models for banking, telco, retail, ecommerce, etc.",
    },

    {
      img: "images/perch/About/mobile/img4.webp",
      img_web: "images/perch/About/web/real.webp",
      title: "Real Time Updates",
      description:
        "Continuous real-time updates of the platform from streaming sources",
    },
  ];

  const data = [
    {
      img: "/images/perch/Carsouel/holistic_web.webp",
      img_mob: "/images/perch/Carsouel/holistic_mob.webp",
      title: "Holistic view of customers",
      description:
        "Single source of truth about customers from disparate sources",
    },
    {
      img: "/images/perch/Carsouel/nbo_web.webp",
      img_mob: "/images/perch/Carsouel/nbo_mob.webp",
      title: "NBO/NBA",
      description:
        "Marketing, next-best action & support - improve engagement by having a holistic view of all customer activities",
    },
    {
      img: "/images/perch/Carsouel/store_web.webp",
      img_mob: "/images/perch/Carsouel/store_mob.webp",
      title: "Feature store",
      description:
        "Feature store for scoring of ML models - simplify training and productionalization of ML models with an always updated feature store",
    },
  ];

  const [screenSize, setScreenSize] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenSize(window.innerWidth);
    };

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const Layout = ({ children, backgroundColor }) => {
    return screenSize <= 768 ? (
      <MobileLayout backgroundColor={backgroundColor}>{children}</MobileLayout>
    ) : (
      <DesktopLayout backgroundColor={backgroundColor}>
        {children}
      </DesktopLayout>
    );
  };

  return (
    <div>
      <DesktopHero
        heroImage={"images/perch/hero.webp"}
        heading="Industry-Specific Customer Data Models & Platform"
        title={"PERCH"}
        backgroundColor="pink"
      />

      <MobileHero
        heading={"Industry-Specific Customer Data Models & Platform"}
        title={"PERCH"}
        backgroundColor="pink"
      />

      <Layout>
        <div className=" sm:hidden w-[88%] mx-auto">
          <div>&nbsp;</div>
          <img
            src="/images/perch/perch_hero_mob.webp"
            alt="frame1"
            className=" mt-[40px]"
          />
        </div>
      </Layout>

      <Layout>
        <div>
          {" "}
          <TextContainer
            text1="Perch comprises a set of industry-specific customer 360 degree data models for banking, telecommunications, retail and other industries. Furthermore, it includes a framework for deploying a customer data platform that can host the customer 360-degree view"
            text2="Bring together all your data into single view of your customer, stitched together from dozens of data sources, and turbo-charge marketing, support and fraud-detection use-cases."
          />
          <HeadingSubHeadingContainer
            heading="Take your customer data to the next level with Perch"
            subHeading="Enable several use-cases with a reliable, trustworthy customer data model & platform"
          />
          <Slider data={data} />
        </div>
      </Layout>

      <Layout backgroundColor="black">
        <About
          title={"Why Perch?"}
          description={
            "Any ML or AI use-case around customer behavior needs a holistic view of customers that pulls in data from different systems and touchpoints. Perch helps you accelerate the development of a customer 360 degree view."
          }
          img1={"/images/perch/img1.webp"}
          img2={"/images/perch/img2.webp"}
          img3={"/images/perch/img3.webp"}
          img4={"/images/perch/img4.webp"}
          data={aboutData}
        />
        <AboutMobile
          title={"Why Perch?"}
          description={
            "Any ML or AI use-case around customer behavior needs a holistic view of customers that pulls in data from different systems and touchpoints. Perch helps you accelerate the development of a customer 360 degree view."
          }
          data={aboutData}
        />
      </Layout>

      <Layout>
        <WhitePaper
          heading={"Meet your business goals with Perch"}
          subHeading={"Welcome to the future of business."}
          title={"Go Deeper with Perch"}
          description={
            "Discover how Perch can improve data driven decision making in your enterprise."
          }
          carouselData={carouselData}
        />
      </Layout>

      <Layout backgroundColor="black">
        <Contact
          title={
            "See how Perch can simplify data-driven decision making in your enterprise"
          }
          type="solution"
        />
        <Footer />
      </Layout>
    </div>
  );
}

export default Perch;
