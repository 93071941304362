import "./App.css";
import "./styles/common.css";
// import "./styles/form.css";
import { Route, Routes, BrowserRouter, Navigate } from "react-router-dom";
import Home from "./components/home";
import Chattr from "./components/chattr";
import Perch from "./components/perch";
import Pique from "./components/pique";
import GenerativeAI from "./components/generative-ai";

import DigitalExperience from "./components/digital-experience";
import DataPlatform from "./components/data-platform";
import Kuwago from "./components/kuwago";
import AnalyticsAndML from "./components/analytics-and-ml";
import Hoots from "./components/hoots";
import Story from "./components/story";
import ContactUs from "./components/contact-us";
import Random from "./components/random";

import "./fonts/Roobert-Bold.woff2";
import "./fonts/Roobert-Medium.woff2";
import "./fonts/Roobert-Regular.woff2";
import "./fonts/Roobert-SemiBold.woff2";
import Customer from "./components/customer";
import React, { lazy, Suspense } from "react";

import NewRandom from "./components/new-random";

function App() {
  const LazyComponent = lazy(() => import("./components/story"));

  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/home" element={<Home />} />
        <Route exact path="/hoots" element={<Hoots />} />
        <Route exact path="/kuwago" element={<Kuwago />} />
        <Route exact path="/chattr" element={<Chattr />} />
        <Route exact path="/generative-ai" element={<GenerativeAI />} />
        <Route exact path="/pique" element={<Pique />} />
        <Route exact path="/perch" element={<Perch />} />
        <Route exact path="/data-platform" element={<DataPlatform />} />
        <Route
          exact
          path="/story"
          element={
            <Suspense fallback={<div>Loading...</div>}>
              <LazyComponent />
            </Suspense>
          }
        />
        <Route
          exact
          path="/digital-experience"
          element={<DigitalExperience />}
        />
        <Route exact path="/analytics-and-ml" element={<AnalyticsAndML />} />
        <Route exact path="/customer" element={<Customer />} />
        <Route exact path="/contact-us" element={<ContactUs />} />
        <Route exact path="/random" element={<Random />} />
        <Route exact path="/new-random" element={<NewRandom />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
