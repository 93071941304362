function Product({ title, description, list }) {
  const cardList = list.map((item, index) => {
    return (
      <div key={index} className="w-full">
        <div>{item.svg}</div>

        <div className="mt-[15px] uppercase tracking-[0.845px] text-[13px] RoobertRegular text-[#000]">
          {item.title}
        </div>
        <div className="mt-[17px] text-[18px] leading-[120%] RoobertRegular text-[#000]">
          {item.description}
        </div>
      </div>
    );
  });

  return (
    <div className=" py-[90px] w-[87%] sm:w-[80%] mx-auto">
      <div className="">
        <div className="">
          <div className="sm:w-[55%] RoobertMedium leading-[120%] text-[48px] text-[#000]">
            {title}
          </div>
          <div className="mt-[24px] sm:w-[53%] leading-[120%] text-[18px] RoobertRegular text-[#000]">
            {description}
          </div>
        </div>
      </div>
      <div className="mt-[94px] bg-white p-6 hidden sm:flex justify-between items-start shrink-0 rounded-[20px]">
        <div
          className={`${
            list.length < 6 ? "w-[22%]" : "w-[38%]"
          } flex flex-col justify-end self-stretch rounded-[24px]`}
        >
          <img src="images/customer/img1.webp" alt="image1" />
        </div>
        <div className="ml-[22%] w-[60%]">
          <div className="grid grid-cols-2 gap-y-[51px] 2xl:gap-y-[80px] gap-x-[71px] 2xl:gap-x-[91px]">
            {cardList}
          </div>
          {/* <div className="mt-[115px]">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="38"
              height="38"
              viewBox="0 0 38 38"
              fill="none"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M0.745068 37.2596C0.491228 37.0057 0.491227 36.5942 0.745068 36.3403L35.6355 1.4499L10.8047 1.44991C10.4457 1.44991 10.1547 1.15889 10.1547 0.799905C10.1547 0.440921 10.4457 0.149906 10.8047 0.149906L37.2047 0.149902C37.3771 0.149902 37.5424 0.218385 37.6643 0.340283C37.7862 0.462183 37.8547 0.627512 37.8547 0.799902L37.8547 27.1999C37.8547 27.5589 37.5637 27.8499 37.2047 27.8499C36.8457 27.8499 36.5547 27.5589 36.5547 27.1999L36.5547 2.36914L1.66431 37.2596C1.41047 37.5134 0.998909 37.5134 0.745068 37.2596Z"
                fill="#808080"
              />
            </svg>
          </div> */}
        </div>
      </div>

      <div className="sm:hidden p-6 flex flex-col gap-[58px] bg-[#FFF] rounded-[20px] mt-[68px]">
        <div>
          <img src="images/common/product_mob.webp" alt="product" />
        </div>
        <div className="flex flex-col gap-[71px] py-[20px] pr-[15px]">
          {cardList}
        </div>
        <div className="mt-[115px]">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="38"
            height="38"
            viewBox="0 0 38 38"
            fill="none"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M0.745068 37.2596C0.491228 37.0057 0.491227 36.5942 0.745068 36.3403L35.6355 1.4499L10.8047 1.44991C10.4457 1.44991 10.1547 1.15889 10.1547 0.799905C10.1547 0.440921 10.4457 0.149906 10.8047 0.149906L37.2047 0.149902C37.3771 0.149902 37.5424 0.218385 37.6643 0.340283C37.7862 0.462183 37.8547 0.627512 37.8547 0.799902L37.8547 27.1999C37.8547 27.5589 37.5637 27.8499 37.2047 27.8499C36.8457 27.8499 36.5547 27.5589 36.5547 27.1999L36.5547 2.36914L1.66431 37.2596C1.41047 37.5134 0.998909 37.5134 0.745068 37.2596Z"
              fill="#414141"
            />
          </svg>
        </div>
      </div>
    </div>
  );
}

export default Product;
