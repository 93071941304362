import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const HorizontalCarousel = ({ data }) => {
  const CustomPrevArrow = (props) => {
    const { style, onClick } = props;
    return (
      <button
        className="custom-arrow top-[98%] sm:top-[-10px] right-[53%] sm:right-[40px] opacity-50"
        style={{ ...style }}
        onClick={onClick}
      >
        <img src="images/common/arrow-left.svg" alt="arrow" />
      </button>
    );
  };

  const CustomNextArrow = (props) => {
    const { style, onClick } = props;
    return (
      <button
        className="custom-arrow top-[98%] sm:top-[-10px] right-[47%] sm:right-[20px] opacity-50"
        style={{ ...style }}
        onClick={onClick}
      >
        {/* Your custom next arrow content */}

        <img src="images/common/arrow-right.svg" alt="arrow" />
      </button>
    );
  };

  const settings = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
  };

  const list = data.map((item, index) => {
    return (
      <div key={index} className="mt-3 px-[40px]">
        <div className="text-[24px] RoobertMedium leading-[120%] text-[#000]">
          {item.title}
        </div>
        <div className="mt-4 mb-[62px] sm:mb-[0px] text-[18px] leading-[120%] opacity-80 RoobertRegular text-[#000]">
          {item.description}
        </div>
      </div>
    );
  });

  return (
    <div className="bg-white  py-[30px] flex rounded-[5px]">
      <div className="carousel-container">
        <Slider {...settings}>{list}</Slider>
      </div>
    </div>
  );
};

export default HorizontalCarousel;
