import TextContainer from "./shared/textContainer";

import WhitePaper from "./shared/whitepaper";
import Header from "./shared/header";
import Contact from "./shared/contact";
import Footer from "./shared/footer";

import MobileLayout from "./shared/mobile_layout";
import { useState, useEffect } from "react";
import MobileHero from "./shared/mobile_hero";
import DesktopLayout from "./shared/desktop_layout";
import HeroBackground from "./shared/hero_background";
import DesktopHero from "./shared/desktop_hero";
import About from "./shared/about";
import AboutMobile from "./shared/about_mobile";
import Slider from "./shared/slider";
import HeadingSubHeadingContainer from "./shared/headingSubHeadingContainer";

function Pique() {
  const carouselData = [
    {
      title: "Boost Customer Engagement",
      description:
        "Set up chatbot for bank in Philippines with 90% increase in NLP accuracy while engaging in over 15,000 conversations per month.",
    },
    {
      title: "Drive Digital Adoption",
      description:
        "We received the platform on time and within budget but also enabled us to think outside the box in terms of the capabilities and services that could transform our business. In short, they are the definition of an innovative tech partner.",
    },
  ];

  const data = [
    {
      img: "/images/pique/Carsouel/boost_web.webp",
      img_mob: "/images/pique/Carsouel/boost_mob.webp",
      title: "Boost customer engagement",
      description:
        "Continuous reinforcement learning to adapt content presented ",
    },
    {
      img: "/images/pique/Carsouel/cross_web.webp",
      img_mob: "/images/pique/Carsouel/cross_mob.webp",
      title: "Cross-sell and up-sell products",
      description:
        "Powered by propensity, persuadability, collaborative filtering and other ML models",
    },
    {
      img: "/images/pique/Carsouel/insights_web.webp",
      img_mob: "/images/pique/Carsouel/insights_mob.webp",
      title: "Provide Insights",
      description:
        "Automatically generate insights on customer activity, financial planning and unusual observations",
    },
  ];

  const aboutData = [
    {
      img: "images/pique/About/mobile/img1.webp",
      img_web: "images/pique/About/web/customer.webp",
      title: "Generative AI-powered Customer Engagement",
      description:
        "Uses Generative AI to create relevant content in appropriate tone",
    },

    {
      img: "images/pique/About/mobile/img2.webp",
      img_web: "images/pique/About/web/cross.webp",
      title: "Cross-channel Engagement",
      description:
        "Hyper-personalized customer engagement engine for website, app or chat platforms",
    },

    {
      img: "images/pique/About/mobile/img3.webp",
      img_web: "images/pique/About/web/real.webp",
      title: "Real-time Personalized Insights & Offers",
      description:
        "Powered by a real-time data and ML platform for maintaining a 360-degree view of customers.",
    },

    {
      img: "images/pique/About/mobile/img4.webp",
      img_web: "images/pique/About/web/designed.webp",
      title: "Designed for Finance and Telecommunication",
      description:
        "Extensive list of use-cases for banking and telecommunications domains.",
    },

    {
      img: "images/pique/About/mobile/img5.webp",
      img_web: "images/pique/About/web/personalized.webp",
      title: "Personalized Offer Creation",
      description:
        "Automatic generation of personalized offers and insights for customers based on past activity",
    },
  ];

  const [screenSize, setScreenSize] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenSize(window.innerWidth);
    };

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const Layout = ({ children, backgroundColor }) => {
    return screenSize <= 768 ? (
      <MobileLayout backgroundColor={backgroundColor}>{children}</MobileLayout>
    ) : (
      <DesktopLayout backgroundColor={backgroundColor}>
        {children}
      </DesktopLayout>
    );
  };

  return (
    <div>
      <DesktopHero
        heroImage="/images/pique/hero.webp"
        heading="Hyper-personalized customer engagement at scale"
        title="PIQUE"
        backgroundColor={"pink"}
      />

      <MobileHero
        heading={"Omni-channel communication with your customers at scale"}
        title="PIQUE"
        backgroundColor="pink"
      />

      <Layout>
        <div className=" sm:hidden w-[88%] mx-auto">
          <div>&nbsp;</div>
          <img
            src="/images/pique/pique_hero_mob.webp"
            alt="frame1"
            className=" mt-[40px]"
          />
        </div>
      </Layout>

      <Layout>
        <div>
          <TextContainer
            text1="Pique is a generative AI powered hyper-personalized customer engagement engine for digital platforms, such as apps, websites and messaging platforms. It makes use of a variety of first party and third party customer-related datasets to generate appropriate content in a personalized manner for each customer. This content can be shown on apps, website, emails or chat platforms."
            text2="Deploy fast with pre-defined use-cases that have accompanying logic and models for industry-specific needs."
          />
          <HeadingSubHeadingContainer
            heading="Take customer engagement to the next level with Pique"
            subHeading="Improve your customers’ in-app experience and drive engagement with a combination of greetings, insights, and upsell and cross-sell offers."
          />
          <div>
            <Slider data={data} />
          </div>
        </div>
      </Layout>

      <Layout backgroundColor="black">
        <About
          title={"Why Pique?"}
          description={
            "Most enterprises, today, struggle to personalize user experience.Pique is a hyper-personalized customer engagement engine for your digital platforms, with the goal of improving your customers in-app experience and driving engagement. It comes with an extensive list of use-cases for the banking and telecommunications domains. These use-cases span a variety of greetings, cross-sell and upsell opportunities, and insight categories that reveal interesting findings based on the customer positions and behavioral patterns. "
          }
          img1={"/images/kuwago/powered-search.webp"}
          img2={"/images/kuwago/indexing.webp"}
          img3={"/images/kuwago/processing.webp"}
          img4={"/images/kuwago/secure.webp"}
          data={aboutData}
        />

        <AboutMobile
          title={"Why Pique?"}
          description={
            "Most enterprises, today, struggle to personalize user experience.Pique is a hyper-personalized customer engagement engine for your digital platforms, with the goal of improving your customers in-app experience and driving engagement. It comes with an extensive list of use-cases for the banking and telecommunications domains. These use-cases span a variety of greetings, cross-sell and upsell opportunities, and insight categories that reveal interesting findings based on the customer positions and behavioral patterns. "
          }
          data={aboutData}
        />
      </Layout>

      {/* <Layout backgroundColor="black">
        <About
          title={"Why Pique?"}
          description={
            "Most enterprises, today, struggle to personalize user experience.Pique is a hyper-personalized customer engagement engine for your digital platforms, with the goal of improving your customers in-app experience and driving engagement. It comes with an extensive list of use-cases for the banking and telecommunications domains. These use-cases span a variety of greetings, cross-sell and upsell opportunities, and insight categories that reveal interesting findings based on the customer positions and behavioral patterns. "
          }
          img1={"/images/pique/About/web/img1.png"}
          img2={"/images/pique/About/web/img2.png"}
          img3={"/images/pique/About/web/img3.png"}
          img4={"/images/pique/About/web/img4.png"}
          img5={"/images/pique/About/web/img5.png"}
        />
        <AboutMobile
          title={"Why Pique?"}
          description={
            "Most enterprises, today, struggle to personalize user experience.Pique is a hyper-personalized customer engagement engine for your digital platforms, with the goal of improving your customers in-app experience and driving engagement. It comes with an extensive list of use-cases for the banking and telecommunications domains. These use-cases span a variety of greetings, cross-sell and upsell opportunities, and insight categories that reveal interesting findings based on the customer positions and behavioral patterns. "
          }
          data={aboutData}
        />
      </Layout> */}

      <Layout>
        <WhitePaper
          heading={"Meet your business goals with Pique"}
          subHeading={"Welcome to the future of business."}
          title={"Talk to Customers with Pique"}
          description={
            "Pique paves the way for enterprises to make everyday experiences for their customers be personal, effortless and insightful. This results in improved engagement and ROI for apps and the website. Welcome to the future of hyper-personalized customer engagement. "
          }
          carouselData={carouselData}
        />
      </Layout>

      <Layout backgroundColor="black">
        <Contact
          title={
            "See how Pique can simplify access to data for your enterprise and customers"
          }
          type="solution"
        />
        <Footer />
      </Layout>
    </div>
  );
}

export default Pique;
