import HorizontalCarouselII from "./horizontal-carousel-II";

function TextHorizontalCarousel({ title, description, data }) {
  return (
    <div className="w-[88%] lg:w-[80%] mx-auto">
      <div className="p-[24px] lg:p-[33px] border-[1px] border-[#E7E7E7] flex-col lg:flex-row flex gap-9 bg-white rounded-[20px]">
        <div className="flex items-center w-full lg:w-[37%]">
          <div className=" lg:px-[28px]">
            <h2 className="text-[35px] lg:text-[48px] RoobertMedium leading-[100%] RoobertMedium">
              {title}
            </h2>
            <div className="w-[100%] lg:w-[75%] mt-9 RoobertRegular text-[18px] leading-[120%] RoobertRegular">
              {description}
            </div>
          </div>
        </div>

        <div className="w-full lg:w-[60%] lg:ml-7">
          <HorizontalCarouselII data={data} />
        </div>
      </div>
    </div>
  );
}

export default TextHorizontalCarousel;
