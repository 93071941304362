function Dropdown({ data }) {
  const list = data.map((item, index) => {
    return (
      <a href={item.link} key={index}>
        <div className="py-[10px] pl-[16px] pr-[60px] rounded-[6px]  hover:bg-[#F5F5F5] text-[#262626] text-start">
          <div className=" uppercase text-[14px] tracking-[0.7px] RoobertRegular ">
            {item.title}
          </div>
          <div className="mt-[2px] RoobertRegular leading-[120%] text-[13px] text-[#4d4d4d]">
            {item.descritption}
          </div>
        </div>
      </a>
    );
  });

  return (
    <div>
      {/* add an empty tag in dropdown to solve dropdown not visible on hover */}
      <div>&nbsp;</div>
      <div className="h-1">&nbsp;</div>
      <div className="w-[100%]">
        <div className=" p-3 flex flex-col gap-2 dropdown-card">{list}</div>
      </div>
    </div>
  );
}

export default Dropdown;
