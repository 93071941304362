function BackgroundVideo({ children }) {
  return (
    <div className=" relative overflow-hidden w-[93%] mx-auto mt-[66px] sm:mt-[134px] rounded-[32px] mb-[101px] sm:mb-[134px]">
      <video autoPlay muted loop className="background-video">
        <source
          src="https://onebyzero-public-bucket.s3.ap-southeast-1.amazonaws.com/background-video-new.mp4"
          type="video/mp4"
        />
      </video>

      <div className="gradient-overlay hidden md:block">
        <img src="/images/common/contact_background_web.svg" alt="background" />
      </div>
      <div className="gradient-overlay  md:hidden">
        <img src="/images/common/contact_background_mob.svg" alt="background" />
      </div>

      <div className="z-[0] relative">{children}</div>
    </div>
  );
}

function Contact({ title, type }) {
  return (
    <div className="">
      <div>&nbsp;</div>
      <BackgroundVideo>
        <div className="">
          <div>&nbsp;</div>
          <h2 className="mt-[146px] sm:mt-[168px] text-[42px] sm:text-[52px] text-[#000] RoobertMedium w-[85%] sm:w-[55%] mx-auto sm:mx-0 sm:ml-[70px] leading-[100%] sm:leading-[52px]">
            {title}
          </h2>
          <a
            href="/contact-us"
            // className={`${type === "service" ? "mb-[45px]" : "mb-[185px]"}`}
          >
            <button
              className={` ${
                type === "service"
                  ? "mb-[161px] mt-[64px]"
                  : "mb-[161px] mt-[73px] sm:mt-[64px]"
              } flex py-[10px] ml-[20px] sm:ml-[70px] px-4 bg-[black] hover:bg-[#333333] rounded-[8px] items-center justify-center`}
            >
              <div className="text-[16px] RoobertRegular text-[#FFF]">
                Contact Sales
              </div>

              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="17"
                height="17"
                viewBox="0 0 17 17"
                fill="none"
                className=" ml-[10px]"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M4.1816 12.8204C3.98634 12.6251 3.98634 12.3085 4.1816 12.1133L11.8281 4.4668L6.80182 4.4668C6.52568 4.4668 6.30182 4.24294 6.30182 3.9668C6.30182 3.69065 6.52568 3.4668 6.80182 3.4668L13.0352 3.4668C13.1678 3.4668 13.2949 3.51948 13.3887 3.61324C13.4825 3.70701 13.5352 3.83419 13.5352 3.9668L13.5352 10.2001C13.5352 10.4763 13.3113 10.7001 13.0352 10.7001C12.759 10.7001 12.5352 10.4763 12.5352 10.2001V5.17391L4.88871 12.8204C4.69345 13.0156 4.37687 13.0156 4.1816 12.8204Z"
                  fill="white"
                />
              </svg>
            </button>
          </a>
          {/* <div>&nbsp;</div> */}
        </div>
      </BackgroundVideo>

      <div>&nbsp;</div>
    </div>
  );
}

export default Contact;
