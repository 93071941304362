import HeadingSubHeadingContainer from "./shared/headingSubHeadingContainer";
import Slider from "./shared/slider";
import About from "./shared/about";
import WhitePaper from "./shared/whitepaper";
import Contact from "./shared/contact";
import Footer from "./shared/footer";
import DesktopLayout from "./shared/desktop_layout";
import MobileLayout from "./shared/mobile_layout";
import { useState, useEffect } from "react";
import MobileHero from "./shared/mobile_hero";
import TextContainer from "./shared/textContainer";
import DesktopHero from "./shared/desktop_hero";
import AboutMobile from "./shared/about_mobile";

function Hoots() {
  const data = [
    {
      img: "/images/hoots/AWARENESS.webp",
      img_mob: "/images/hoots/AWARENESS_mob.webp",
      title: "AWARENESS",
      description:
        "Deliver educational messages, appointment reminders, or notifications to keep customers informed and engaged",
    },
    {
      img: "/images/hoots/E-COMMERCE.webp",
      img_mob: "/images/hoots/E-COMMERCE_mob.webp",
      title: "E-COMMERCE",
      description:
        "Answer customer queries about product features, delivery status, or returns and refunds, enhancing the shopping experience and driving sales.",
    },
    {
      img: "/images/hoots/MARKETING.webp",
      img_mob: "/images/hoots/MARKETING_mob.webp",
      title: "MARKETING",
      description:
        "Send promotional offers, notifications, and personalized suggestions through messaging channels to increase customer engagement and awareness.",
    },
  ];

  // const carouselData = [
  //   {
  //     title: "Customer Satisfaction",
  //     description:
  //       "Improve customer satisfaction rates and lower churn rates by increasing number of channels to interact with customers.",
  //   },
  //   {
  //     title: "Customer Satisfaction",
  //     description:
  //       "Improve customer satisfaction rates and lower churn rates by increasing number of channels to interact with customers.",
  //   },
  // ];

  const aboutData = [
    {
      img: "images/hoots/About/mobile/img1.webp",
      img_web: "images/hoots/About/web/multi.webp",
      title: "Multi Channel Messaging",
      description:
        "Provide consistent experience and 2-way messaging across Messenger, Telegram, Viber, Line, WhatsApp and many more",
    },

    {
      img: "images/hoots/About/mobile/img3.webp",
      img_web: "images/hoots/About/web/enterprise.webp",
      title: "Easily integrated with your enterprise architecture",
      description:
        "Secure and easy integration with existing tech stack, CRM, and business tools.",
    },

    {
      img: "images/hoots/About/mobile/img2.webp",
      img_web: "images/hoots/About/web/single.webp",
      title: "Single API across all channels",
      description:
        "Single integration with automated adaptation of all communications based on features of the channel",
    },

    {
      img: "images/hoots/About/mobile/img4.webp",
      img_web: "images/hoots/About/web/dashboard.webp",
      title: "Single Dashboard for Simplified Monitoring & Analytics",
      description:
        "Manage all messaging channels and conversations in one place with a single dashboard.",
    },
  ];

  const WhitePapercardData = [
    {
      img: "images/hoots/nps-score.webp",
      svg: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="32"
          height="33"
          viewBox="0 0 32 33"
          fill="none"
        >
          <g opacity="0.5">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M16.0076 2.64355C8.20455 2.64355 1.87891 8.9692 1.87891 16.7723C1.87891 24.5753 8.20455 30.901 16.0076 30.901C23.8106 30.901 30.1363 24.5753 30.1363 16.7723C30.1363 8.9692 23.8106 2.64355 16.0076 2.64355ZM3.90557 16.7723C3.90557 10.0885 9.32385 4.67022 16.0076 4.67022C22.6913 4.67022 28.1097 10.0885 28.1097 16.7723C28.1097 23.456 22.6913 28.8743 16.0076 28.8743C9.32385 28.8743 3.90557 23.456 3.90557 16.7723ZM10.7504 20.4289C10.4134 19.9458 9.74851 19.8273 9.26534 20.1643C8.78216 20.5013 8.66368 21.1662 9.00071 21.6494C10.5409 23.8575 13.1032 25.3058 16.0039 25.3058C18.9046 25.3058 21.4669 23.8575 23.0071 21.6494C23.3441 21.1662 23.2256 20.5013 22.7425 20.1643C22.2593 19.8273 21.5944 19.9458 21.2574 20.4289C20.099 22.0896 18.1779 23.1725 16.0039 23.1725C13.83 23.1725 11.9088 22.0896 10.7504 20.4289ZM11.4672 15.3861C12.4982 15.3861 13.3339 14.5502 13.3339 13.5194C13.3339 12.4886 12.4982 11.6527 11.4672 11.6527C10.4363 11.6527 9.60057 12.4886 9.60057 13.5194C9.60057 14.5502 10.4363 15.3861 11.4672 15.3861ZM22.4006 13.5194C22.4006 14.5502 21.5648 15.3861 20.5339 15.3861C19.503 15.3861 18.6672 14.5502 18.6672 13.5194C18.6672 12.4886 19.503 11.6527 20.5339 11.6527C21.5648 11.6527 22.4006 12.4886 22.4006 13.5194Z"
              fill="black"
            />
          </g>
        </svg>
      ),
      title: "NPS scores",
      description:
        "Over 90% of customers rate immediate response to customer service questions as important.",
    },
    {
      img: "images/hoots/XXX.webp",
      svg: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="32"
          height="33"
          viewBox="0 0 32 33"
          fill="none"
        >
          <g opacity="0.5">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M15.9953 1.90137C11.7243 1.90137 8.26198 5.3637 8.26198 9.6347C8.26198 13.1629 10.6247 16.1392 13.8542 17.0678C11.3067 17.3781 9.12161 18.2791 7.47561 19.9013C5.38086 21.9657 4.32031 25.0391 4.32031 29.0496C4.32031 29.6093 4.774 30.063 5.33365 30.063C5.89329 30.063 6.34698 29.6093 6.34698 29.0496C6.34698 25.3803 7.31302 22.907 8.89819 21.3448C10.4864 19.7796 12.8573 18.9697 16.0002 18.9697C19.143 18.9697 21.5141 19.7796 23.1023 21.3448C24.6875 22.907 25.6536 25.3803 25.6535 29.0496C25.6535 29.6092 26.1072 30.0629 26.6668 30.063C27.2265 30.063 27.6802 29.6093 27.6802 29.0496C27.6803 25.0391 26.6197 21.9657 24.5248 19.9013C22.8773 18.2777 20.6898 17.3765 18.1393 17.067C21.3673 16.1375 23.7286 13.1618 23.7286 9.6347C23.7286 5.3637 20.2663 1.90137 15.9953 1.90137ZM10.2886 9.6347C10.2886 6.48299 12.8436 3.92803 15.9953 3.92803C19.147 3.92803 21.702 6.48299 21.702 9.6347C21.702 12.7864 19.147 15.3414 15.9953 15.3414C12.8436 15.3414 10.2886 12.7864 10.2886 9.6347Z"
              fill="black"
            />
          </g>
        </svg>
      ),
      title: "Improved Retention",
      description:
        "Improve customer retention rates by upto 91% with onmi-channel communication strategies",
    },
  ];

  const [screenSize, setScreenSize] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenSize(window.innerWidth);
    };

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const Layout = ({ children, backgroundColor }) => {
    return screenSize <= 768 ? (
      <MobileLayout backgroundColor={backgroundColor}>{children}</MobileLayout>
    ) : (
      <DesktopLayout backgroundColor={backgroundColor}>
        {children}
      </DesktopLayout>
    );
  };

  return (
    <div>
      <DesktopHero
        heroImage={"images/hoots/hero.webp"}
        heading="Omni-channel communication with your customers at scale"
        title={"HOOTS"}
        backgroundColor="violet"
      />

      <MobileHero
        heading={"Omni-channel communication with your customers at scale"}
        title={"HOOTS"}
        backgroundColor="violet"
      />

      <Layout>
        <div className=" sm:hidden w-[88%] mx-auto">
          <div>&nbsp;</div>
          <img
            src="/images/hoots/hoots_hero_mob.webp"
            alt="frame1"
            className=" mt-[40px]"
          />
        </div>
      </Layout>

      {/* <BackgroundVideo /> */}

      <Layout>
        <div>
          <div>
            <TextContainer
              text1="Hoots is a revolutionary platform that enables businesses to
              communicate with their customers seamlessly across multiple
              messaging channels."
              text2=" Integrate with WhatsApp, Messenger, Viber, Line, Telegram and
              more, and provide a truly seamless and personalized customer
              experience that increases engagement, satisfaction, and
              ultimately drives revenue."
              optional="hoots"
            />
          </div>
          <HeadingSubHeadingContainer
            heading="Take communication with your customers to the next level"
            subHeading="Reach customers seamlessly across multiple end-points with just a single API. Say goodbye to the intricate nuances of different channels. Handle a variety of use-cases from Marketing and Awareness to E-Commerce."
          />
          <Slider data={data} swiperHeight={432} />
        </div>
      </Layout>

      <Layout backgroundColor="black">
        <About
          title={"Why Hoots?"}
          description={
            "With our omni channel messaging product, businesses can engage with their customers in a whole new way, building lasting relationships and driving growth. Hoots can serve a variety of inbound and outbound communication use-cases for businesses."
          }
          img1={"/images/hoots/messaging.webp"}
          img2={"/images/hoots/enterprise.webp"}
          img3={"/images/hoots/single.webp"}
          img4={"/images/hoots/single-dashboard.webp"}
          data={aboutData}
        />
        <AboutMobile
          title={"Why Hoots?"}
          description={
            "With our omni channel messaging product, businesses can engage with their customers in a whole new way, building lasting relationships and driving growth. Hoots can serve a variety of inbound and outbound communication use-cases for businesses."
          }
          data={aboutData}
        />
      </Layout>

      <Layout>
        <WhitePaper
          heading={"Meet your business goals with Hoots"}
          subHeading={"Welcome to the future of business communication."}
          title={"Go Deeper with Hoots"}
          description={
            "Discover how you can improve customer engagement rates and lower churn by increasing the number channels on which you interact with customers"
          }
          // carouselData={carouselData}
          cardData={WhitePapercardData}
        />
      </Layout>

      <Layout backgroundColor="black">
        <Contact
          title="Reach out to see how Hoots can help streamline your conversations."
          type="solution"
        />
        <Footer />
      </Layout>
    </div>
  );
}

export default Hoots;
