export default function SmallCard({
  title,
  heading,
  img,
  description,
  tag,
  toggleModal,
  index,
}) {
  const isLargeHeading = heading.length > 10;
  const Body = (
    <>
      {" "}
      <div className=" relative py-[20px] pl-[12px] md:pl-[21px] pr-[30px] md:pr-[50px] flex flex-col md:flex-row gap-[19px] bg-white rounded-[16px] border-[1px] border-[#E7E7E7]">
        <div className="w-[40%] md:w-[55%] md:max-w-[275px] md:min-w-[225px]">
          <div className="">
            {img === "" ? (
              ""
            ) : (
              <>
                <img
                  src={img}
                  alt="celcom"
                  className="h-[75px] w-[75px] md:h-[261px] md:w-full items-center"
                />
              </>
            )}
          </div>
        </div>
        <div className="flex flex-col md:w-[45%]">
          <div className="text-[24px] text-left leading-[120%] text-[#000] RoobertMedium">
            {title}
          </div>
          <div className="mt-[19px] bg-[#F3EAFF] py-[5px] px-[8px] flex w-fit md:min-w-[135px]  justify-center uppercase text-[9px]  text-[#000] RoobertLight tracking-[1.845px] rounded-[4px]">
            {tag}
          </div>
          <div className="mt-[19px] py-[9px] md:py-[0px] gap-[14px] md:gap-0 md:mt-0 flex-grow-[1] md:w-[50%] md:min-w-[150px] justify-center text-[#000] text-center items-center flex md:flex-col">
            <div
              className={`RoobertMedium ${
                isLargeHeading ? "text-[22px]" : "text-[32px]"
              }  leading-[100%]`}
            >
              {heading}
            </div>
            <div className="RoobertRegular text-[12px] leading-[120%] mt-[3px]">
              {description}
            </div>
          </div>
        </div>

        <button
          className="hidden md:block mt-[20px] md:absolute bottom-[10%] right-[30px]"
          onClick={() => toggleModal(index)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="26"
            height="26"
            viewBox="0 0 26 26"
            fill="none"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M0.331006 25.6703C0.0771649 25.4165 0.0771649 25.0049 0.331005 24.7511L22.9056 2.17647L7.10642 2.17647C6.74743 2.17647 6.45642 1.88545 6.45642 1.52647C6.45642 1.16748 6.74743 0.876467 7.10642 0.876468L24.4748 0.876465C24.6472 0.876465 24.8126 0.944947 24.9345 1.06685C25.0564 1.18874 25.1248 1.35407 25.1248 1.52646L25.1248 18.8949C25.1248 19.2539 24.8338 19.5449 24.4748 19.5449C24.1159 19.5449 23.8248 19.2539 23.8248 18.8949L23.8248 3.09571L1.25024 25.6703C0.996404 25.9242 0.584847 25.9242 0.331006 25.6703Z"
              fill="#808080"
            />
          </svg>
        </button>
      </div>
    </>
  );

  const handleClick = () => {
    console.log("clicked");
    toggleModal(index);
  };

  return (
    <>
      <button className="md:hidden w-full" onClick={handleClick}>
        {Body}
      </button>
      <div className="hidden md:block w-full">{Body}</div>
    </>
  );
}
