function VerticalCarousel() {
  const sliderData = [
    {
      img: "/images/generative-ai/Enterprise.webp",
      title: "Enterprise Search",
      description:
        "Natural language search and question-answering on wide swathes of enterprise data, such as file repositories, content management systems, chat platforms, code repositories, BI tools, data glossaries and catalogs, etc.",
    },
    {
      img: "/images/generative-ai/Consumable.webp",
      title: "Consumable Data & Insights",
      description:
        "Simplify the management,  governance, query and usage of structured enterprise datasets. This also includes use of generative AI for automated generation of glossaries, catalogs, quality checks and tests.",
    },
    {
      img: "/images/generative-ai/Customer.webp",
      title: "Customer Engagement",
      description:
        "Simplify the management,  governance, query and usage of structured enterprise datasets. This also includes use of generative AI for automated generation of glossaries, catalogs, quality checks and tests. ",
    },
    {
      img: "/images/generative-ai/Next.webp",
      title: "Next-gen Marketing",
      description:
        "Automated generation of spiels, creatives and other content and configurations for both broad-based and personalized marketing campaigns.",
    },
    {
      img: "/images/generative-ai/productivity.webp",
      title: "Productivity Boosters",
      description:
        "For developers and IT staff, generate specific kinds of code that meet enterprise guidelines, as well as generate test cases in an automated manner. Simplify orchestration of workflows across different enterprise APIs via agents, aiding employees in tasks around HR, CRM, reporting, project management and collaboration. ",
    },
  ];

  return (
    <div className="w-[90%] sm:w-[80%] mb-[46px] sm:mb-[116px] carousel rounded-[10px] sm:rounded-[20px] carousel-vertical flex mx-auto items-center h-[710px]">
      <div className="flex flex-col sm:flex-row p-6 bg-white  rounded-[20px] sm:rounded-none sm:carousel-item"></div>
    </div>
  );
}

function NewRandom() {
  <VerticalCarousel />;
}

export default NewRandom;
