import { useState, useEffect } from "react";
import Header from "./shared/header";

import DesktopLayout from "./shared/desktop_layout";
import MobileLayout from "./shared/mobile_layout";
import Form from "./shared/form/form";

function ContactUs() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [company, setCompany] = useState("");
  const [jobTitle, setJobTitle] = useState("");
  const [intrest, setIntrest] = useState("");
  const [project, setProject] = useState("");

  const handleSend = () => {
    console.log("Send Data");
  };

  const [screenSize, setScreenSize] = useState(window.innerWidth);

  useEffect(() => {
    // console.log("hewej");
    const handleResize = () => {
      setScreenSize(window.innerWidth);
    };

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const Layout = ({ children, backgroundColor }) => {
    return screenSize <= 768 ? (
      <MobileLayout backgroundColor={backgroundColor}>{children}</MobileLayout>
    ) : (
      <DesktopLayout backgroundColor={backgroundColor}>
        {children}
      </DesktopLayout>
    );
  };

  return (
    <div>
      <Layout>
        <div>
          <Header />
          <div>&nbsp;</div>
          <div className=" mt-[101px] w-[87%] sm:w-[80%] mx-auto text-[#000]">
            <div className="text-[48px] RoobertMedium">Contact Us</div>
            <div className="mt-[24px] leading-[120%] tracking-[-0.4px] text-[20px] RoobertRegular md:w-[44%]">
              Reach out to us to explore how our Generative AI, Machine Learning
              and Data solutions can drive transformative growth for your
              business. We are here to deliver unmatched value, innovation, and
              domain expertise, guiding you towards sustainable success. Reach
              out today to start the conversation.
            </div>
          </div>

          <div className="mt-[69px] flex flex-col sm:flex-row w-[87%] sm:w-[80%] mx-auto mb-[185px]">
            {/* <div className="flex sm:w-[44%] flex-col gap-3 text-[#424242] mt-[40px]">
              <div className="flex gap-3 w-full">
                <input
                  // value={name}
                  // onChange={(event) => setName(event.target.value)}
                  placeholder="Your name"
                  className="w-[50%] py-3 pl-3 pr-[26px] rounded-[12px] outline-none h-[43px] text-[#424242] bg-white border-[1px] RoobertRegular border-[#EBEBEB]"
                ></input>
                <input
                  // value={phoneNumber}
                  // onChange={(event) => setPhoneNumber(event.target.value)}
                  placeholder="Phone number"
                  className="w-[50%] py-3 pl-3 pr-[26px] rounded-[12px] outline-none h-[43px] bg-white border-[1px] border-[#EBEBEB]"
                ></input>
              </div>
              <input
                // value={email}
                // onChange={(event) => setEmail(event.target.value)}
                placeholder="Email"
                style={{ outline: "none" }}
                className="w-full py-3 pl-[18px] pr-[26px] rounded-[12px] focus:outline-none active:outline-none outline-none h-[43px] bg-white border-[1px] border-[#EBEBEB]"
              ></input>
              <div className="flex gap-3 w-full">
                <input
                  // value={company}
                  // onChange={(event) => setCompany(event.target.value)}
                  placeholder="Company"
                  className="w-[50%] py-3 pl-3 pr-[26px] rounded-[12px] outline-none h-[43px] bg-white border-[1px] border-[#EBEBEB]"
                ></input>
                <input
                  // value={jobTitle}
                  // onChange={(event) => setJobTitle(event.target.value)}
                  placeholder="Job Title"
                  className="w-[50%] py-3 pl-3 pr-[26px] rounded-[12px] outline-none h-[43px] bg-white border-[1px] border-[#EBEBEB]"
                ></input>
              </div>
              <input
                // value={intrest}
                // onChange={(event) => setIntrest(event.target.value)}
                placeholder="Are you interested in something specific?"
                style={{ outline: "none" }}
                className="w-full py-3 pl-[18px] pr-[26px] rounded-[12px] outline-none h-[43px] bg-white border-[1px] border-[#EBEBEB]"
              ></input>
              <textarea
                // value={project}
                // onChange={(event) => setProject(event.target.value)}
                style={{ outline: "none" }}
                placeholder="Tell us a little about the project"
                className="w-full py-3 pl-[18px] pr-[26px] outline-none min-h-[125px] rounded-[12px] bg-white border-[1px] border-[#EBEBEB]"
              ></textarea>

              <div className="flex justify-end">
                <button
                  className="w-[38%] py-[10px] px-[12px] bg-black flex relative rounded-[8px]"
                  onClick={handleSend}
                >
                  <div className="text-[#FFF] RoobertRegular">Send it</div>
                  <div className=" absolute right-[12px] top-[33%]">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="15"
                      height="15"
                      viewBox="0 0 15 15"
                      fill="none"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M1.20308 1.04312C1.00481 0.954998 0.772341 1.0048 0.627577 1.16641C0.482813 1.32802 0.458794 1.56455 0.568117 1.75196L3.92115 7.50002L0.568117 13.2481C0.458794 13.4355 0.482813 13.672 0.627577 13.8336C0.772341 13.9952 1.00481 14.045 1.20308 13.9569L14.7031 7.95693C14.8836 7.87668 15 7.69762 15 7.50002C15 7.30243 14.8836 7.12337 14.7031 7.04312L1.20308 1.04312ZM4.84553 7.10002L2.21234 2.586L13.2689 7.50002L2.21234 12.414L4.84552 7.90002H9C9.22092 7.90002 9.4 7.72094 9.4 7.50002C9.4 7.27911 9.22092 7.10002 9 7.10002H4.84553Z"
                        fill="white"
                      />
                    </svg>
                  </div>
                </button>
              </div>
            </div> */}

            <div className="flex sm:w-[44%] flex-col gap-3 text-[#424242] mt-[40px]">
              <iframe
                src="form.html"
                height="420"
                style={{ width: "100%" }}
                title="form"
              ></iframe>
            </div>

            <div className=" bg-white text-[#000] py-[38px] px-[27px] h-fit gap-8 flex flex-col sm:ml-[22.5%] w-full mt-[50px] sm:mt-0 sm:w-[33.5%] rounded-[8px] border-[0.75px] border-[rgba(255, 255, 255, 0.10)]">
              <div className="text-[20px] tracking-[-0.4px]">Or</div>
              <div>
                <div className="text-[20px]">Drop us a hi! </div>
                <div className=" opacity-40">Our team is here to help</div>
                <div>contact@onebyzero.ai</div>
              </div>

              <div>
                <div className="text-[20px]">Visit us</div>
                <div className=" opacity-40">
                  Come say hello at our office HQ
                </div>
                <div className="mt-[10px]">
                  <div>OneByZero @ WeWork</div>
                  <div>109 North Bridge Road</div>
                  <div>#05-21</div>
                  <div>Singapore 179097</div>
                </div>
              </div>

              {/* <div>
                <div className="text-[20px]">Call us</div>
                <div className=" opacity-40">Mon-Fri 9am-6pm SST</div>
                <div className="mt-[10px]">+63 94991 09289</div>
              </div> */}
            </div>
          </div>
          <div>&nbsp;</div>
        </div>
      </Layout>
    </div>
  );
}

export default ContactUs;
