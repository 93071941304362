import CardBody from "./CardBody";

export default function Card({
  imgBackground,
  img_web,
  img_mob,
  title,
  description,
  tag,
  inventionList,
  numberList,
  index,
}) {
  return (
    <div
      className={`flex ${index % 2 === 1 ? "justify-end" : ""} w-full md:w-fit`}
    >
      <div className="pt-[21px] pl-[21px] pr-[21px] md:pr-[50px] pb-[35px] bg-white md:w-[84%] rounded-[16px] border border-[#E7E7E7]">
        <CardBody
          imgBackground={imgBackground}
          img_web={img_web}
          img_mob={img_mob}
          title={title}
          description={description}
          tag={tag}
          inventionList={inventionList}
          numberList={numberList}
          index={index}
        />
      </div>
    </div>
  );
}
