function Deployement({ title, description, data }) {
  const cardList = data.map((item, index) => {
    return (
      <div
        className={`w-full flex flex-col ${
          index % 2 === 0 ? "items-start" : "items-start md:items-end"
        }  gap-6`}
      >
        <div className="md:w-[39.7%] flex flex-col items-start gap-6">
          <div className="text-[35px] RoobertMedium leading-[120%] text-[#FFF]">
            {index + 1}
          </div>
          <div className="text-[18px] RoobertLight uppercase tracking-[3.69px] leading-[120%] text-[#FFF]">
            {item.title}
          </div>
        </div>

        <div
          className={`flex ${
            index % 2 === 0
              ? "flex-col md:flex-row"
              : "flex-col md:flex-row-reverse"
          } md:justify-between items-start w-full`}
        >
          <div className=" md:w-[40%] text-[24px] RoobertMedium leading-[120%] text-[#FFF] self-stretch md:self-auto md:shrink-0">
            {item.description}
          </div>
          <div className="md:w-[55.5%] hidden md:block shrink-0 rounded-[18px] mt-6 md:mt-0">
            <img src={item.img} alt={item.title} />
          </div>
          <div className="md:w-[55.5%] md:hidden shrink-0 rounded-[18px] mt-6 md:mt-0">
            <img src={item.img_mob} alt={item.title} />
          </div>
        </div>
      </div>
    );
  });

  return (
    <div>
      <div>&nbsp;</div>
      <div className="mt-[85px] text-[#EDEEF3] w-[87%] md:w-[80%] mx-auto">
        <div className="text-[35px] md:text-[48px] leading-[120%] RoobertMedium">
          {title}
        </div>
        <div className="mt-5 md:mt-[11px] text-[18px] md:w-[35%] RoobertRegular leading-[120%]">
          {description}
        </div>
      </div>

      <div className="mt-[150px] md:mt-[200px] mb-[129px] md:mb-[209px]  w-[87%] md:w-[80%] mx-auto flex flex-col items-start gap-[120px] px-[13px] md:px-0 md:gap-[200px]">
        {cardList}
      </div>

      <div>&nbsp;</div>
    </div>
  );
}

export default Deployement;
