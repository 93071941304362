import Modal from "react-overlays/Modal";
export default function CustomerModal({ element, open, onClose }) {
  // Backdrop JSX code
  const renderBackdrop = (props) => <div className="backdrop" {...props} />;

  return (
    <div className="modal-example rounded-[16px] relative w-full max-w-7xl max-h-full flex justify-center">
      <Modal
        className="custom-modal md:h-[80%] pt-[21px] px-[21px] pb-[35px] bg-white rounded-[16px] border border-[#E7E7E7] overflow-scroll"
        show={open}
        renderBackdrop={renderBackdrop}
        style={{ borderRadius: "16px" }}
        onHide={onClose}
      >
        {element}
      </Modal>
    </div>
  );
}
