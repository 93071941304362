// Initialization for ES Users

import { useRef, useEffect } from "react";
import MobileLayout from "./shared/mobile_layout";

// Initialization for ES Users

import { useState } from "react";
import DesktopLayout from "./shared/desktop_layout";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import Header from "./shared/header";

import Marquee, { Motion, randomIntFromInterval } from "react-marquee-slider";
import times from "lodash/times";
import HeroBackground from "./shared/hero_background";
import useVisible from "./shared/useIsVisible";

function IntractiveCard({
  img,
  title,
  description,
  id,
  setCardState,
  count,
  fadeProp,
}) {
  const list = [];
  for (let i = 0; i < count; i++) {
    list.push(i);
  }
  const circles = list.map((index) => {
    return id === index ? (
      <button
        key={index}
        className={`h-[5px] sm:h-[10px] w-[5px] sm:w-[10px] #item${index} bg-black rounded-[50px] sm:rounded-[100px] block ${
          index > 0 ? "mt-2 sm:mt-4" : "mt-0"
        }`}
        onClick={() => setCardState(index)}
      ></button>
    ) : (
      <button
        key={index}
        className={`h-[5px] sm:h-[10px] w-[5px] sm:w-[10px] #item${index} border-[#000000] border-[0.3px] sm:border-[0.6px] rounded-[50px] sm:rounded-[100px] block ${
          index > 0 ? "mt-2 sm:mt-4" : "mt-0"
        }`}
        onClick={() => setCardState(index)}
      ></button>
    );
  });

  return (
    <div
      className={`flex flex-col sm:flex-row p-6 bg-white #item${id} rounded-[20px] sm:rounded-none sm:carousel-item slider-child`}
    >
      <div className="sm:w-[55%]">
        <img
          src={img}
          alt={title}
          className={` ${fadeProp} w-full`}
          loading="lazy"
        />
      </div>
      <div className="sm:w-[36%] pt-[47px] pl-[18px] sm:pl-[42px] sm:pr-[24px] pb-[10px] sm:pb-[20px] flex items-start sm:items-center">
        <div>
          <div
            className={`${fadeProp} RoobertLight tracking-[3.69px] text-[18px] sm:text-[16px] uppercase`}
          >
            {title}
          </div>

          <div
            className={`${fadeProp} sm:w-[90%] mt-[57px] RoobertRegular leading-[120%] text-[18px]`}
          >
            {description}
          </div>
          <div className={`w-[40%] ${fadeProp} sm:w-[20%]`}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="38"
              height="39"
              viewBox="0 0 38 39"
              fill="none"
              className="mt-[28px] sm:mt-[57px] hidden sm:flex"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M0.745068 38.1922C0.491228 37.9384 0.491227 37.5268 0.745068 37.273L35.6355 2.38252L10.8047 2.38252C10.4457 2.38252 10.1547 2.09151 10.1547 1.73252C10.1547 1.37354 10.4457 1.08252 10.8047 1.08252L37.2047 1.08252C37.3771 1.08252 37.5424 1.151 37.6643 1.2729C37.7862 1.3948 37.8547 1.56013 37.8547 1.73252L37.8547 28.1326C37.8547 28.4916 37.5637 28.7826 37.2047 28.7826C36.8457 28.7826 36.5547 28.4916 36.5547 28.1326L36.5547 3.30176L1.66431 38.1922C1.41047 38.446 0.998909 38.446 0.745068 38.1922Z"
                fill="#808080"
              />
            </svg>

            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="25"
              viewBox="0 0 25 25"
              fill="none"
              className="sm:hidden mt-[57px]"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M0.299756 24.4588C0.0459148 24.205 0.0459148 23.7934 0.299756 23.5396L21.927 1.9123L6.82254 1.91231C6.46355 1.91231 6.17254 1.62129 6.17254 1.26231C6.17254 0.903321 6.46355 0.612305 6.82254 0.612307L23.4962 0.612305C23.6686 0.612305 23.8339 0.680787 23.9558 0.802686C24.0777 0.924585 24.1462 1.08991 24.1462 1.2623L24.1462 17.936C24.1462 18.295 23.8552 18.586 23.4962 18.586C23.1372 18.586 22.8462 18.295 22.8462 17.936L22.8462 2.83155L1.21899 24.4588C0.965154 24.7126 0.553597 24.7126 0.299756 24.4588Z"
                fill="#414141"
              />
            </svg>
          </div>
        </div>
      </div>
      <div className="w-[5%] sm:w-[9%] py-[10px] sm:py-[20px] px-[5px] sm:px-[10px] hidden sm:flex items-center justify-center">
        <div>{circles}</div>
      </div>
    </div>
  );
}

// const Card = () => {
//   const [text, setText] = useState("Old Text");

//   const toggleTransition = function () {
//     setText(text === "Old Text" ? "New Text" : "Old Text");
//   };

//   return (
//     <div className="App">
//       <h1>React: Text Transition Example</h1>
//       <SwitchTransition mode="out-in">
//         <CSSTransition
//           classNames="fade"
//           addEndListener={(node, done) => {
//             node.addEventListener("transitionend", done, false);
//           }}
//           key={text}
//         >
//           <div class="text">{text}</div>
//         </CSSTransition>
//       </SwitchTransition>
//       <button onClick={toggleTransition}>Toggle Transition</button>
//     </div>
//   );
// };

let timeOutId = null;

function Slider({ data, parentRef, isVisible, setHideScroll }) {
  const carouselRef = useRef(null);
  const [fadeProp, setFadeProp] = useState("fade-in");

  const setCardState = (id) => {
    setFadeProp("fade-out");
    if (timeOutId != null) {
      clearTimeout(timeOutId);
    }
    timeOutId = setTimeout(() => {
      setFadeProp("fade-in");
      setCurrentCard(id);
    }, [1000]);
    const cardHeight = carouselRef.current.offsetHeight; // Adjust this based on your carousel's actual dimensions
    const containerHeight = carouselRef.current.scrollHeight;
    const maxScrollTop = containerHeight - carouselRef.current.clientHeight;

    //console.log("scrollTop", carouselRef.current.scrollTop);

    const scrollTop = id === data.length - 1 ? maxScrollTop : cardHeight * id;

    carouselRef.current.scrollTo({
      top: scrollTop,
      behavior: "smooth",
      scrollTimingFunction: "ease-in-out", // You can also try different easing functions
      duration: 20, // Adjust this value
    });
  };

  const list = data.map((item, index) => {
    return (
      <IntractiveCard
        key={index}
        img={item.img}
        title={item.title}
        description={item.description}
        id={index}
        setCardState={setCardState}
        count={data.length}
      />
    );
  });

  const [screenSize, setScreenSize] = useState(window.innerWidth);

  const [currentCard, setCurrentCard] = useState(0);

  useEffect(() => {
    const handleWheel = (e) => {
      if (e.deltaY > 0) {
        // Scrolling down

        if (currentCard < data.length - 1 && isVisible) {
          setHideScroll(true);
          setFadeProp("fade-out");
          if (timeOutId != null) {
            clearTimeout(timeOutId);
          }
          timeOutId = setTimeout(() => {
            setFadeProp("fade-in");
            setCurrentCard(currentCard + 1);
          }, [200]);
        } else {
          setHideScroll(false);
        }
      } else if (e.deltaY < 0) {
        // Scrolling up

        if (currentCard > 0 && isVisible) {
          setHideScroll(true);
          setFadeProp("fade-out");
          if (timeOutId != null) {
            clearTimeout(timeOutId);
          }
          timeOutId = setTimeout(() => {
            setFadeProp("fade-in");
            setCurrentCard(currentCard - 1);
          }, [200]);
        } else {
          setHideScroll(false);
        }
      }
    };

    // Add event listener for wheel (scroll) events
    parentRef.current.addEventListener("wheel", handleWheel);

    return () => {
      // Remove the event listener when the component unmounts
      parentRef.current.removeEventListener("wheel", handleWheel);
    };
  }, [currentCard, data]);

  //console.log("currentCard", currentCard);

  // useEffect(() => {
  //   if (screenSize >= 500) {
  //     const parentElement = carouselRef.current;
  //     const childElement = document.getElementsByClassName("slider-child")[0];
  //     console.log("childElement", ch)
  //     if (parentElement != null && childElement != null) {
  //       const resizeObserver = new ResizeObserver((entries) => {
  //         for (let entry of entries) {
  //           if (entry.target === childElement) {
  //             parentElement.style.height = `${childElement.clientHeight}px`;
  //           }
  //         }
  //       });

  //       resizeObserver.observe(childElement);

  //       return () => {
  //         resizeObserver.unobserve(childElement);
  //       };
  //     }
  //   }
  // }, [screenSize]);

  return screenSize >= 768 ? (
    <div id="parent">
      <div
        ref={carouselRef}
        className="w-[90%] sm:w-[80%]  carousel rounded-[10px] sm:rounded-[20px] carousel-vertical flex mx-auto items-center  h-[710px]"
      >
        {" "}
        <IntractiveCard
          key={currentCard}
          img={data[currentCard].img}
          title={data[currentCard].title}
          description={data[currentCard].description}
          id={currentCard}
          setCardState={setCardState}
          count={data.length}
          fadeProp={fadeProp}
        />
      </div>
      <div>&nbsp;</div>
    </div>
  ) : (
    <div>
      <div className="w-[88%] gap-[68px] flex flex-col mx-auto mb-[226px]">
        <IntractiveCard
          key={currentCard}
          img={data[currentCard].img}
          title={data[currentCard].title}
          description={data[currentCard].description}
          id={currentCard}
          setCardState={setCardState}
          count={data.length}
        />
      </div>
      <div>&nbsp;</div>
    </div>
  );
}

function TransitionCard() {
  const data = ["Hello", "Rohit", "Yes"];

  const [id, setId] = useState(0);
  const [fadeProp, setFadeProp] = useState("fade-in");

  const handleClick = (e) => {
    e.preventDefault();

    setFadeProp("fade-out");
    setTimeout(() => {
      setFadeProp("fade-in");
      setId((id + 1) % 3);
    }, 700);
  };

  return (
    <div className="mt-[100px] w-[50%] mx-auto p-8 bg-white items-center justify-center transition-card">
      <div className={`${fadeProp}`}>{data[id]}</div>
      <button
        className=" mt-10 p-3 bg-yellow-300 rounded-lg"
        onClick={(e) => handleClick(e)}
      >
        Next
      </button>
    </div>
  );
}

const Box = ({ title, dataList }) => {
  const list = dataList.map((text) => {
    return (
      <div className="leading-[100%] tracking-[-0.35px] RoobertMedium opacity-50 text-[#FFF]">
        {text}
      </div>
    );
  });

  return (
    <div className=" flex flex-col items-start shrink-0 gap-4">
      <div className="text-[14px] text-[#FFF] leading-[100%] tracking-[-0.35px] RoobertMedium">
        {title}
      </div>
      <div className=" text-[14px] flex flex-col items-start gap-[6px]">
        {list}
      </div>
    </div>
  );
};

function Footer() {
  return (
    <DesktopLayout backgroundColor={"black"}>
      <div>&nbsp;</div>
      <div className="w-[74%] mt-[200px] mb-[200px] mx-auto flex justify-between items-start content-start flex-wrap">
        <Box
          title={"Solutions"}
          dataList={["Hoots", "Kuwago", "Pique", "Owl Talk", "Perch"]}
        />
        <Box
          title={"Services"}
          dataList={[
            "Generative AI",
            "Analytics & Machine Learning",
            "Data Platforms",
            "Customer Engagement",
          ]}
        />
        <Box
          title={"About"}
          dataList={["Company", "Team", "Careers", "Blog"]}
        />
        <Box
          title={"Contact"}
          dataList={["Email", "Facebook", "Twitter", "Instagram"]}
        />
      </div>
      <div>&nbsp;</div>
    </DesktopLayout>
  );
}

function Random() {
  const sliderData = [
    {
      img: "/images/generative-ai/Enterprise.webp",
      title: "Enterprise Search",
      description:
        "Natural language search and question-answering on wide swathes of enterprise data, such as file repositories, content management systems, chat platforms, code repositories, BI tools, data glossaries and catalogs, etc.",
    },
    {
      img: "/images/generative-ai/Consumable.webp",
      title: "Consumable Data & Insights",
      description:
        "Simplify the management,  governance, query and usage of structured enterprise datasets. This also includes use of generative AI for automated generation of glossaries, catalogs, quality checks and tests.",
    },
    {
      img: "/images/generative-ai/Customer.webp",
      title: "Customer Engagement",
      description:
        "Simplify the management,  governance, query and usage of structured enterprise datasets. This also includes use of generative AI for automated generation of glossaries, catalogs, quality checks and tests. ",
    },
    {
      img: "/images/generative-ai/Next.webp",
      title: "Next-gen Marketing",
      description:
        "Automated generation of spiels, creatives and other content and configurations for both broad-based and personalized marketing campaigns.",
    },
    {
      img: "/images/generative-ai/productivity.webp",
      title: "Productivity Boosters",
      description:
        "For developers and IT staff, generate specific kinds of code that meet enterprise guidelines, as well as generate test cases in an automated manner. Simplify orchestration of workflows across different enterprise APIs via agents, aiding employees in tasks around HR, CRM, reporting, project management and collaboration. ",
    },
  ];

  const elementRef = useRef();

  const OPTIONS = {
    root: null,
    rootMargin: "0px 0px 0px 0px",
    threshold: 1,
  };

  const [isVisible, setIsVisible] = useState(false);
  const [hideScroll, setHideScroll] = useState(false);

  useEffect(() => {
    if (elementRef.current) {
      const observer = new IntersectionObserver((entries, observer) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsVisible(true);
            observer.unobserve(elementRef.current);
          } else {
            setIsVisible(false);
          }
        });
      }, OPTIONS);
      observer.observe(elementRef.current);
    }
  }, [elementRef]);

  // if (isVisible) {
  //   const tag = document.getElementsByTagName("body")[0];
  //   console.log("tag", tag);

  //   //stop body scrolling, scrolling inside component could be happed
  //   document.getElementsByTagName("body")[0].style.height = "100%";
  //   document.getElementsByTagName("body")[0].style.overflow = "hidden";

  //   console.log("slider visible");
  // }

  useEffect(() => {
    if (isVisible && hideScroll) {
      document.getElementsByTagName("body")[0].style.height = "100%";
      document.getElementsByTagName("body")[0].style.overflow = "hidden";
    } else {
      document.getElementsByTagName("body")[0].style.height = "";
      document.getElementsByTagName("body")[0].style.overflow = "";
    }

    if (isVisible) {
      console.log("slider visible");
    } else {
      console.log("slider invisible");
    }
  }, [isVisible, hideScroll]);

  // useEffect(() => {
  //   const handleWheel = (e) => {
  //     if (elementRef.current) {
  //       const rect = elementRef.current.getBoundingClientRect();
  //       const windowHeigth = window.innerHeight;
  //       const containerHeight = rect.height;
  //       const topDistanceWhenCardInMiddle =
  //         (windowHeigth - 16 - 43 - containerHeight) / 2 + 16 + 43;

  //       console.log(
  //         "topDistanceWhenCardInMiddle ",
  //         topDistanceWhenCardInMiddle
  //       );

  //       if (rect.top <= topDistanceWhenCardInMiddle) {
  //         setIsVisible(true);
  //       } else {
  //         setIsVisible(false);
  //       }

  //       console.log("rect.top ", rect.top);
  //     }
  //   };

  //   // Add event listener for wheel (scroll) events
  //   elementRef.current.addEventListener("wheel", handleWheel);

  //   return () => {
  //     // Remove the event listener when the component unmounts
  //     elementRef.current.removeEventListener("wheel", handleWheel);
  //   };
  // });

  return (
    <DesktopLayout>
      <Header />

      <div>&nbsp;</div>
      <div>&nbsp;</div>
      <div>&nbsp;</div>
      <div>&nbsp;</div>

      <div>&nbsp;</div>
      <div>&nbsp;</div>
      <div>&nbsp;</div>
      <div>&nbsp;</div>
      <div>&nbsp;</div>
      <TransitionCard />
      <TransitionCard />
      <TransitionCard />
      <TransitionCard />
      <div className="mb-[100px]">&nbsp;</div>
      <div
        ref={elementRef}
        className={`${
          hideScroll && isVisible ? "sticky top-[79px] overflow-hidden" : ""
        }`}
      >
        <Slider
          data={sliderData}
          parentRef={elementRef}
          isVisible={isVisible}
          setHideScroll={setHideScroll}
        />
      </div>

      <TransitionCard />
      <TransitionCard />
      <TransitionCard />
      <TransitionCard />
      <TransitionCard />
      <div className="w-[80%] mx-auto">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 987 578"
          fill="linear-gradient(217deg, rgba(21, 22, 23, 0.70) 0%, rgba(51, 55, 66, 0.50) 100%)"
          className="w-[81%] h-[100%]"
        >
          <path
            d="M14 0C6.26758 0 0 6.12971 0 13.692V564.308C0 571.87 6.26758 578 14 578H390C397.732 578 404 571.732 404 564V412.717C404 405.155 410.268 399.025 418 399.025H973C980.732 399.025 987 392.757 987 385.025V13.692C987 6.12971 980.732 0 973 0H14Z"
            fill="url(#paint0_linear_1967_6631)"
          />
          <defs>
            <linearGradient
              id="paint0_linear_1967_6631"
              x1="987"
              y1="-2.57822e-05"
              x2="615.48"
              y2="847.734"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#151617" stop-opacity="0.7" />
              <stop offset="1" stop-color="#333742" stop-opacity="0.5" />
            </linearGradient>
          </defs>
        </svg>

        <svg viewBox="0 0 20 10" xmlns="http://www.w3.org/2000/svg">
          <circle cx="5" cy="5" r="4">
            <title>I'm a circle</title>
          </circle>

          <rect x="11" y="1" width="8" height="8">
            <title>I'm a square</title>
          </rect>
        </svg>
      </div>

      <div className="">
        <div
          style={{
            clipPath:
              "polygon(0 0, 100% 0, 100% 60%, 33% 60%, 33% 100%, 0 100%)",
            backgroundColor: "black",
          }}
          className="flex w-[65%] ml-[10%] clip-card"
        >
          <div className="w-[32%] p-[10px]">
            <img src="images/common/try-1.png" />
          </div>
          <div className="ml-[80px] w-[28%] mt-[90px] text-[#FFF]">
            <div>Strategy</div>
            <div className="mt-[20px]">
              Help define an over-arching customer-engagement strategy, covering
              omni-channel interactions, campaigns and personalization.
            </div>
          </div>
        </div>
      </div>

      <div className="mb-[20px] relative top-[-165px] h-[338px]">
        <div
          style={{
            clipPath:
              "polygon(0 54%, 12% 54%, 12% 0, 100% 0, 100% 100%, 0 100%)",
            background:
              "linear-gradient(217deg, rgba(21, 22, 23, 0.70) 0%, rgba(51, 55, 66, 0.50) 100%)",
          }}
          className="flex flex-row-reverse w-[65%] ml-[25%] clip-card"
        >
          <div className="w-[35%] p-[10px]">
            <img src="images/common/try-2.png" className="w-full" />
          </div>
          <div className=" w-[40%] mr-[200px] items-start mt-[20%] text-[#FFF]">
            <div>Customer 360 degree view</div>
            <div className="mt-[20px]">
              Set up data ingestion pipelines from multiple sources and maintain
              360-degree view of customer
            </div>
          </div>
        </div>
      </div>

      {/* <div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="987"
          height="578"
          viewBox="0 0 987 578"
          fill="none"
        >
          <path
            d="M14 0C6.26758 0 0 6.12971 0 13.692V564.308C0 571.87 6.26758 578 14 578H390C397.732 578 404 571.732 404 564V412.717C404 405.155 410.268 399.025 418 399.025H973C980.732 399.025 987 392.757 987 385.025V13.692C987 6.12971 980.732 0 973 0H14Z"
            fill="url(#paint0_linear_1967_6631)"
          />
          <defs>
            <linearGradient
              id="paint0_linear_1967_6631"
              x1="987"
              y1="-2.57822e-05"
              x2="615.48"
              y2="847.734"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#151617" stop-opacity="0.7" />
              <stop offset="1" stop-color="#333742" stop-opacity="0.5" />
            </linearGradient>
          </defs>
        </svg>
      </div> */}

      <Footer />
    </DesktopLayout>
  );
}

export default Random;
