import "./styles/hero.css";
function HeroBackground({ children, backgroundColor }) {
  return (
    <div
      className={`video-container ${
        backgroundColor === ""
          ? "block h-[608px] md:h-[712px]"
          : "hidden md:block h-[880px]"
      } `}
    >
      <video
        id="background-video"
        autoPlay
        loop
        muted
        playsInline
        className="background-video"
      >
        <source
          src="https://onebyzero-public-bucket.s3.ap-southeast-1.amazonaws.com/background-video-new.mp4"
          type="video/mp4"
        />
        {/* problem:- video not playing on mobile devices
            solution:- encoded video handbrake software with weboptimize option checked
         */}

        {/* Add additional <source> elements for other video formats if needed */}
      </video>
      <div className={`gradient-overlay ${backgroundColor}`}>
        {backgroundColor === "" ? null : (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="1358"
            height="845"
            viewBox="0 0 1358 845"
            fill="none"
          >
            <g opacity="0.8" filter="url(#filter0_f_1609_6240)">
              <path
                d="M-138.324 443.272C-159.277 468.829 -167.132 493.674 -168.753 523.141C-166.384 527.134 -163.54 531.062 -163.257 535.889C-162.721 545.04 -163.257 554.369 -163.257 563.536C-163.257 583.077 -165.97 606.298 -152.248 622.209C-125.565 653.148 -100.739 689.198 -70.6479 717.745C-48.0437 739.189 -30.2849 767.067 1.23761 779.029C20.1884 786.22 36.4082 796.755 56.7708 801.3C76.836 805.779 96.8022 811.793 116.675 816.506C164.13 827.761 214.25 823.418 262.875 823.418C310.823 823.418 357.347 829.632 405.675 828.947C455.986 828.235 486.885 806.395 518.522 773.653C545.686 745.541 566.383 712.635 596.236 686.412C614.928 669.993 636.443 656.887 657.274 643.713C671.268 634.862 686.714 630.415 700.826 622.977C758.292 592.691 842.355 605.007 905.635 605.007C933.333 605.007 960.828 603.464 987.883 602.242C1016.64 600.943 1044.23 589.317 1072.4 585.5C1106.66 580.857 1137.47 554.476 1171.48 549.866C1188.63 547.543 1214.11 531.343 1215.2 513.772C1215.73 505.222 1221.02 496.854 1221.02 488.889V452.948C1221.02 431.794 1228.93 395.325 1211.63 378.916C1179.85 348.765 1120.36 353.546 1079.68 344.972C1022.86 332.993 942.611 334.125 898.188 291.982C878.987 273.767 856.368 257.838 836.988 239.452C825.893 228.928 816.983 217.337 803.635 208.426C790.568 199.703 774.659 193.07 762.188 183.39C736.857 163.729 700.654 156.422 670.388 147.449C625.85 134.246 579.934 135.008 533.417 135.008C473.538 135.008 416.135 143.394 358.237 157.126C311.92 168.111 265.174 173.408 221.427 193.067C180.755 211.344 135.82 223.219 99.0278 247.746C68.8104 267.89 26.2439 281.665 2.20903 309.184C-11.1879 324.523 -30.6273 336.744 -46.2004 350.04C-65.9332 366.888 -92.0309 387.461 -105.134 410.249C-112.68 423.374 -128.708 431.543 -138.324 443.272Z"
                fill="white"
              />
            </g>
            <defs>
              <filter
                id="filter0_f_1609_6240"
                x="-303.754"
                y="0"
                width="1661.58"
                height="964"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="BackgroundImageFix"
                  result="shape"
                />
                <feGaussianBlur
                  stdDeviation="67.5"
                  result="effect1_foregroundBlur_1609_6240"
                />
              </filter>
            </defs>
          </svg>

          // <img src="images/common/hero_background_web.png" />
        )}
      </div>
      <div className="content h-full">
        <div className="h-full">{children}</div>
      </div>
    </div>
  );
}

export default HeroBackground;
