function Card({ title, description }) {
  return (
    <div className="px-[34px] bg-white pt-[43px] pb-8 rounded-[12px] w-full md:w-[29%] border-[0.6px] border-[#E7E7E7]">
      <div className="px-[17px] pb-3">
        {" "}
        <h3 className="text-[29px] text-black leading-[100%] RoobertMedium">
          {title}
        </h3>
        <div className="mt-[10px] text-black RoobertRegularBody">
          {description}
        </div>
      </div>
    </div>
  );
}

function Process({ optional = "none" }) {
  const data = [
    {
      title: "Outcome Driven Approach",
      description:
        "We prioritize alignment with our clients' goals, ensuring that all our work is geared towards maximizing value from data and achieving tangible results.",
    },
    {
      title: "Assets, IP and Accelerators",
      description:
        "Our suite of innovative tools and solutions enables clients to rapidly realize value from their data, while also differentiating our offerings from competitors.",
    },
    {
      title: "Deep Domain Expertise",
      description:
        "Our talented team of experts possesses a wealth of knowledge in Cloud, Data, AI, and various business domains. We understand and address clients' specific KPIs and metrics, leading to more effective and targeted solutions.",
    },
  ];

  return (
    <div className="px-[19px] pt-[90px] pb-[150px] w-[97%] md:w-[82.5%] mx-auto">
      {optional === "story" ? (
        <></>
      ) : (
        <h2 className="mb-[94px] md:mb-[64px] RoobertMediumBody text-[35px] md:text-[48px]">
          Our Process
        </h2>
      )}

      <div className="flex flex-col md:flex-row gap-[68px] md:gap-0 justify-between">
        {data.map((item, index) => {
          return (
            <Card
              key={index}
              title={item.title}
              description={item.description}
            />
          );
        })}
      </div>
    </div>
  );
}

export default Process;
