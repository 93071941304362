// Initialization for ES Users

import { useRef, useState, useEffect, useCallback } from "react";
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from "body-scroll-lock";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";

function IntractiveCard({
  img,
  img_mob,
  title,
  description,
  id,
  setCardState,
  count,
}) {
  const list = [];
  for (let i = 0; i < count; i++) {
    list.push(i);
  }

  return (
    <div
      id={id}
      className={`flex flex-col sm:flex-row p-6 bg-white #item${id} rounded-[20px]`}
    >
      <div className="sm:w-[55%] hidden sm:block">
        <img src={img} alt={title} className="w-full h-96 xl:h-[700px] " />
      </div>
      <div className="sm:w-[55%] sm:hidden">
        <img src={img_mob} alt={title} className="w-full" />
      </div>
      <div className="sm:w-[40%] pt-[47px] pl-[18px] sm:pl-[42px] sm:pr-[24px] pb-[10px] sm:pb-[20px] flex items-start sm:items-center">
        <div>
          <div className=" RoobertLight tracking-[3.69px]  text-[18px] sm:text-[16px] uppercase text-[#000]">
            {title}
          </div>
          <div className="sm:w-[90%] mt-[57px] RoobertRegular leading-[120%] text-[18px] text-[#080808]">
            {description}
          </div>
          {/* <div className="w-[40%] sm:w-[20%]">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="38"
              height="39"
              viewBox="0 0 38 39"
              fill="none"
              className="mt-[28px] sm:mt-[57px] hidden sm:flex"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M0.745068 38.1922C0.491228 37.9384 0.491227 37.5268 0.745068 37.273L35.6355 2.38252L10.8047 2.38252C10.4457 2.38252 10.1547 2.09151 10.1547 1.73252C10.1547 1.37354 10.4457 1.08252 10.8047 1.08252L37.2047 1.08252C37.3771 1.08252 37.5424 1.151 37.6643 1.2729C37.7862 1.3948 37.8547 1.56013 37.8547 1.73252L37.8547 28.1326C37.8547 28.4916 37.5637 28.7826 37.2047 28.7826C36.8457 28.7826 36.5547 28.4916 36.5547 28.1326L36.5547 3.30176L1.66431 38.1922C1.41047 38.446 0.998909 38.446 0.745068 38.1922Z"
                fill="#808080"
              />
            </svg>

            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="25"
              viewBox="0 0 25 25"
              fill="none"
              className="sm:hidden mt-[57px]"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M0.299756 24.4588C0.0459148 24.205 0.0459148 23.7934 0.299756 23.5396L21.927 1.9123L6.82254 1.91231C6.46355 1.91231 6.17254 1.62129 6.17254 1.26231C6.17254 0.903321 6.46355 0.612305 6.82254 0.612307L23.4962 0.612305C23.6686 0.612305 23.8339 0.680787 23.9558 0.802686C24.0777 0.924585 24.1462 1.08991 24.1462 1.2623L24.1462 17.936C24.1462 18.295 23.8552 18.586 23.4962 18.586C23.1372 18.586 22.8462 18.295 22.8462 17.936L22.8462 2.83155L1.21899 24.4588C0.965154 24.7126 0.553597 24.7126 0.299756 24.4588Z"
                fill="#414141"
              />
            </svg>
          </div> */}
        </div>
      </div>
    </div>
  );
}

function Slider({ data, swiperHeight }) {
  const carouselRef = useRef(null);
  const bodyRef = useRef(null);

  const throttledWheelHandler = useCallback((wheelHandler, wait, swiper) => {
    var time = Date.now();

    return function (event) {
      // we dismiss every wheel event with deltaY less than 4
      if (Math.abs(event.deltaY) < 4) return;

      if (event.deltaY < 0) {
        // Scrolling up
        bodyRef.current.removeEventListener(
          "wheel",
          throttledWheelHandler(wheelHandler, 1000, swiper)
        );
        enableBodyScroll(bodyRef.current);
        return;
      }

      if (event.deltaY > 0 && swiper.isEnd) {
        // Scrolling up
        bodyRef.current.removeEventListener(
          "wheel",
          throttledWheelHandler(wheelHandler, 1000, swiper)
        );
        enableBodyScroll(bodyRef.current);
        return;
      }

      if (time + wait - Date.now() < 0) {
        wheelHandler(event);
        time = Date.now();
      }
    };
  }, []);

  const setUpIntersectionObserver = useCallback((swiper) => {
    bodyRef.current = document.querySelector("body");
    const swiperEl = document.querySelector("#animation-carousel");

    function wheelHandler(e) {
      swiper.slideNext(400, true);
      if (swiper.isEnd) {
        enableBodyScroll(bodyRef.current);
        bodyRef.current.removeEventListener(
          "wheel",
          throttledWheelHandler(wheelHandler, 1000, swiper)
        );
      }
    }

    const threshold =
      swiperEl.getBoundingClientRect().height > window.innerHeight ? 0.8 : 1.0;
    // swiper.destroy(false, false)
    let options = {
      root: null,
      rootMargin: "0px",
      // Use a box across the app to give margin for transparent background instead of extra height
      threshold: threshold,
    };

    let callback = (entries, observer) => {
      entries.forEach((entry) => {
        // if intersecting with animation carousel, on next scroll prevent body scroll, and
        // scroll to next carousel item on scroll
        if (entry.isIntersecting) {
          if (swiper.isEnd) {
            console.log("swiper.isEnd", swiper.isEnd);
            return;
          }
          console.log("disableBodyScroll");
          disableBodyScroll(bodyRef.current);

          bodyRef.current.addEventListener(
            "wheel",
            throttledWheelHandler(wheelHandler, 1000, swiper)
          );
        }
      });
    };

    let observer = new IntersectionObserver(callback, options);

    let target = document.querySelector("#animation-carousel");
    observer.observe(target);

    return () => observer.unobserve(target);
  }, []);

  const setCardState = (id) => {
    const cardHeight = carouselRef.current.offsetHeight; // Adjust this based on your carousel's actual dimensions
    const containerHeight = carouselRef.current.scrollHeight;
    const maxScrollTop = containerHeight - carouselRef.current.clientHeight;

    const scrollTop = id === data.length - 1 ? maxScrollTop : cardHeight * id;

    carouselRef.current.scrollTo({
      top: scrollTop,
      behavior: "smooth",
      scrollTimingFunction: "ease-in-out", // You can also try different easing functions
      duration: 0, // Adjust this value
    });
  };

  const list = data.map((item, index) => {
    return (
      <IntractiveCard
        key={index}
        img={item.img}
        img_mob={item.img_mob}
        title={item.title}
        description={item.description}
        id={index}
        setCardState={setCardState}
        count={data.length}
      />
    );
  });

  const [screenSize, setScreenSize] = useState(window.innerWidth);

  useEffect(() => {
    if (screenSize >= 500) {
      const parentElement = carouselRef.current;
      const childElement = document.getElementById(0);
      if (parentElement != null && childElement != null) {
        const resizeObserver = new ResizeObserver((entries) => {
          for (let entry of entries) {
            if (entry.target === childElement) {
              parentElement.style.height = `${childElement.clientHeight}px`;
            }
          }
        });

        resizeObserver.observe(childElement);

        return () => {
          resizeObserver.unobserve(childElement);
        };
      }
    }
  }, [screenSize]);

  useEffect(() => {
    const handleResize = () => {
      clearAllBodyScrollLocks();
      setScreenSize(window.innerWidth);
    };

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [screenSize]);

  const handleTransitionStart = (swiper) => {
    console.log("Transition started");
    const targetElement = document.querySelector("body");
    disableBodyScroll(targetElement);
  };

  const handleTransitionEnd = (swiper) => {
    console.log("Transition ended");
    const targetElement = document.querySelector("body");
    enableBodyScroll(targetElement);
  };

  const handleReachEnd = (swiper) => {
    console.log("Reached end");
    const targetElement = document.querySelector("#animation-carousel");
    enableBodyScroll(targetElement);
  };

  return screenSize >= 768 ? (
    <>
      <div style={{ maxHeight: "100vh", width: "80%", margin: "0 auto" }}>
        <Swiper
          ref={carouselRef}
          id="animation-carousel"
          direction={"vertical"}
          modules={[Pagination]}
          pagination={{
            type: "bullets",
            bulletClass: "swiper-bullet-vertical",
            bulletActiveClass: "swiper-bullet-active-vertical",
            verticalClass: "swiper-pagination-vertical",
            clickable: true,
          }}
          spaceBetween={30}
          onInit={(swiper) => setUpIntersectionObserver(swiper)}
          onTransitionStart={handleTransitionStart}
          // onTransitionEnd={handleTransitionEnd}
          onReachEnd={handleReachEnd}
          style={{ height: `${swiperHeight}px` }}
          className="vertical-swiper-scroll-slide"
        >
          {data.map((item, index) => {
            return (
              <SwiperSlide key={index}>
                <IntractiveCard
                  key={index}
                  img={item.img}
                  title={item.title}
                  description={item.description}
                  id={index}
                  setCardState={setCardState}
                  count={data.length}
                />
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
      <div style={{ height: "116px" }}></div>
    </>
  ) : (
    <div>
      <div className="w-[88%] gap-[68px] flex flex-col mx-auto mb-[129px]">
        {list}
      </div>
      <div>&nbsp;</div>
    </div>
  );
}

export default Slider;
