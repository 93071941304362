function HeadingSubHeadingContainer({ heading, subHeading }) {
  return (
    <div>
      <div className="w-[88%] sm:w-[80.5%] mx-auto mt-[64px] sm:mt-[65px] mb-[70px]">
        <div className="sm:w-[60%] text-[35px] sm:text-[48px] RoobertMedium leading-[120%] text-[#0D0E12]">
          {heading}
        </div>
        <div className="sm:w-[58%] mt-6 RoobertRegular text-[18px] leading-[120%] text-[#000]">
          {subHeading}
        </div>
      </div>
      <div>&nbsp;</div>
    </div>
  );
}

export default HeadingSubHeadingContainer;
