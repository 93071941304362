import Header from "./shared/header";
import Footer from "./shared/footer";
import MobileLayout from "./shared/mobile_layout";
import { useState, useEffect } from "react";

import Contact from "./shared/contact";
import DesktopLayout from "./shared/desktop_layout";
import Process from "./shared/home/process";
import Enterprise from "./shared/home/enterprise";

function Story() {
  const enterpriseData = [
    {
      title: "Deploy Generative AI applications that provide immediate value",
      description:
        "OneByZero has significant experience in exploiting generative Al for enterprise search, natural language queries, question-answering, summarization, personalization, content generation and chatbot use-cases.",
      img: "images/home/enterprise/enterprise-1.webp",
      img_mob: "images/home/enterprise/enterprise-1-mob.webp",
    },
    {
      title: "Enhance customer experience across all channels",
      description:
        "OneByZero has a variety of assets and experience around personalization, next-best action and omni-channel messaging use-cases that can drive customer acquisition, engagement and retention.",
      img: "images/home/enterprise/enterprise-2.webp",
      img_mob: "images/home/enterprise/enterprise-2-mob.webp",
    },
    {
      title: "Build a modern data foundation to power AI/ML use-cases",
      description:
        "OneByZero will help you deploy a modern data stack, with specific focus on data quality, governance, security, performance and flexibility, that can be the foundation for BI, ML and Al use-cases across the enterprise.",
      img: "images/home/enterprise/enterprise-3.webp",
      img_mob: "images/home/enterprise/enterprise-3-mob.webp",
    },
  ];
  const [screenSize, setScreenSize] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenSize(window.innerWidth);
    };

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const Layout = ({ children, backgroundColor }) => {
    return screenSize <= 768 ? (
      <MobileLayout backgroundColor={backgroundColor}>{children}</MobileLayout>
    ) : (
      <DesktopLayout backgroundColor={backgroundColor}>
        {children}
      </DesktopLayout>
    );
  };

  return (
    <div>
      <Layout>
        <div>
          <Header />
          {/* <div className="w-[87%] mx-auto sm:mx-0 sm:w-[46%] sm:ml-[6%] mt-[261px] text-[42px] sm:text-[52px] leading-[120%] mb-[147px] RoobertLight text-[#000]">
            We’re taking AI&ML from promise to practice, from aspiration to
            action, and from vision to value
          </div> */}
          <div className="sm:ml-[10%] w-[87%] mx-auto sm:mx-0 sm:w-[71%] mt-[90px] text-[18px] leading-[120%]  RoobertRegular text-[#000]">
            OneByZero, a modern consulting company headquartered in Singapore.
            We blend strategy, technology, and data-driven insights to achieve
            clear and quantifiable business outcomes. Our unique approach
            leverages Generative AI, Machine Learning, Martech and Modern Data
            Stack expertise, ensuring a swift return on investment. With our
            razor sharp focus on driving business outcomes, we help Enterprises
            unlock new opportunities and drive growth.
          </div>
          {/* <div className="p-6 sm:px-[36px] sm:py-[32px]  bg-white w-[87%] sm:w-[62.5%] mx-auto sm:mx-0 sm:ml-[27.5%] rounded-[16px] border-[1px] RoobertRegular">
            <div className=" text-[35px] sm:text-[48px] RoobertMedium leading-[120%] sm:leading-[100%] text-[#000]">
              Iterative Experimentation and Agile Mindset
            </div>
            <div className="mt-8 sm:w-[42%] sm:mb-[130px] RoobertRegular text-[#000]">
              A key aspect of any statistical AI/ML project is it’s inherent
              exploratory and non-deterministic nature, i.e., it is rarely a
              straight path from project definition to execution. At OneByZero,
              we are intimately aware of this, and hence approach every project
              with an iterative experimentation and agile mindset that allows us
              to keep adding value over the course of the project. We know how
              to balance research & experimentation with operationalization to
              meet desired project metrics.
            </div>
          </div> */}

          {/* Founding team */}
          {/* <div className="w-[87%] sm:w-[71%] mx-auto sm:mx-0 sm:ml-[10%] mt-[74px] mb-[74px] flex flex-col gap-[35px] sm:gap-0 p-6 sm:px-[36px] sm:py-[32px] bg-white rounded-[16px] border-[1px] border-[#E7E7E7]">
            <div>
              <div className="text-[35px] sm:text-[48px] text-black font-medium RoobertMedium">
                Founders & Team
              </div>
              <div className="sm:w-[40%] text-black RoobertRegular mt-[35px] sm:mt-0">
                We have a strong team of PhDs , as well as seasoned
                practitioners of AI, ML and data. ......
              </div>
            </div>
            <div className="py-[54px] sm:gap-[45px] gap-x-[34px] gap-y-[26px] grid grid-cols-2 sm:flex sm:w-[48%]">
              <div className="sm:flex-1">
                <img src="images/story/vibhore.webp" alt="Vibhore Kumar" />
              </div>
              <div className="sm:flex-1">
                {" "}
                <img src="images/story/anand.webp" alt="Anand" />
              </div>
              <div className="sm:flex-1">
                <img src="images/story/niket.webp" alt="Niket" />
              </div>
            </div>

            <div className="gap-[45px] grid grid-cols-2 gap-x-[34px] gap-y-[26px] sm:flex sm:w-[64%] mt-[14px]">
              <div className="sm:flex-1">
                <img src="images/story/sajal.webp" alt="Sajal" />
              </div>
              <div className="sm:flex-1">
                {" "}
                <img src="images/story/mustafa.webp" alt="Mustafa" />
              </div>
              <div className="sm:flex-1">
                <img src="images/story/Chandrashekhar.webp" alt="Niket" />
              </div>
              <div className="sm:flex-1">
                <img src="images/story/sandeep.webp" alt="Niket" />
              </div>
            </div>
          </div> */}
          {/* Founding team */}

          {/* <div className="w-[87%] sm:w-[44.5%] sm:ml-[45.2%] mx-auto sm:mx-0 mt-[94px] sm:mt-[74px] rounded-[16px] border-[1px] border-[#E7E7E7] mb-[126px]">
            <div className="p-6 sm:px-[36px] sm:py-[32px] bg-white rounded-t-[16px]">
              <div className="text-[35px] sm:text-[48px] font-medium RoobertMedium text-[#000]">
                Join us
              </div>
              <div className="text-[18px] mt-[35px] sm:mt-0 pb-[118px] sm:pb-[157px] sm:w-[70%] RoobertRegular text-[#000]">
                If you are passionate about data, AL and ML, have an inquisitive
                and creative mindset and want to see your efforts go live and
                create value ... join us!
              </div>
            </div>
            <div className=" p-6 sm:px-[36px] sm:py-[32px] bg-white story-card-background  rounded-b-[16px]">
              <div className=" text-[35px] sm:text-[48px] font-medium RoobertMedium text-[#000]">
                Culture
              </div>
              <div className="text-[18px] mt-[35px] sm:mt-0 sm:w-[70%] RoobertRegular text-[#000]">
                We are Customer Obsessed; We Learn and are always Curious; We
                insist on the highest standards; We hire and develop the best;
                We earn trust; We dive deep; We deliver results fast.
              </div>
            </div>
          </div> */}
          <div>&nbsp;</div>
          <Process optional="story" />
        </div>
      </Layout>

      <Layout backgroundColor="black">
        <Enterprise data={enterpriseData} optional="story" />
      </Layout>

      <Layout backgroundColor="black">
        <Contact
          title={
            "See how Generative AI can revolutionize your business, and improve customer engagement and employee productivity"
          }
          type="service"
        />
        <div className="">
          {/* <div>&nbsp;</div>
          <div className="bg-white px-[36px] pt-[60px] pb-[32px] w-[87%] sm:w-[80%] mx-auto mt-[136px] mb-[136px] rounded-[16px] border-[1px] border-[#E7E7E7]">
            <div className="sm:px-[35px]">
              <div className=" text-[35px] sm:text-[48px] font-medium RoobertMedium text-[#000]">
                Strategic Partnerships with industry leaders, delivering
                customer value
              </div>
              <div className="text-[18px] mt-[14px] sm:w-[40%] RoobertRegular text-[#000]">
                Leveraging our partnerships with leading cloud providers like
                AWS, Snowflake, and Databricks, we offer robust cloud
                infrastructure design, implementation, and optimization
                services.
              </div>
            </div>
            <div className="w-[95%] mx-auto hidden sm:block pt-[71px] pb-[43px] px-[11px]">
              <img
                src="images/story/company-frame.png"
                alt="compnay frame"
                className="w-full"
              />
            </div>
            <div className="mx-auto pt-[71px] pb-[43px] sm:hidden">
              <img
                src="images/story/company_frame_mob.png"
                alt="compnay frame"
                className="w-full"
              />
            </div>
          </div>
          <div>&nbsp;</div> */}
          <Footer />
        </div>
      </Layout>
    </div>
  );
}

export default Story;
