function MobileLayout({ children, backgroundColor }) {
  const list = [0, 1].map((_, index) => {
    return (
      <div
        key={index}
        className={`border-r border-[#7B7B7B] ${
          backgroundColor === "black"
            ? "border-opacity-30"
            : "border-opacity-10"
        }  h-full w-full `}
      ></div>
    );
  });

  return (
    <div className="relative">
      <div
        className={` absolute object-cover top-0 left-0 right-0 h-full w-full ${
          backgroundColor === "black" ? "bg-[#000]" : "bg-[#FAF7F7]"
        }  px-[24px]`}
      >
        <div className="grid grid-cols-3 gap-1 w-full h-full">
          <div
            key={2}
            className={`border-r border-[#7B7B7B] ${
              backgroundColor === "black"
                ? "border-opacity-20"
                : "border-opacity-10"
            } h-full w-full border-l`}
          ></div>
          {list}
        </div>
      </div>
      <div className="relative">{children}</div>
    </div>
  );
}

export default MobileLayout;
