import Contact from "./shared/contact";
import Header from "./shared/header";
import Enterprise from "./shared/home/enterprise";
import Testimonail from "./shared/testimonail";
import Footer from "./shared/footer";
import TextHorizontalCarousel from "./shared/texHorizontalCarousel.js";
import DesktopLayout from "./shared/desktop_layout";
import MobileLayout from "./shared/mobile_layout";
import HeroBackground from "./shared/hero_background";
import TextContainer from "./shared/textContainer";
import Process from "./shared/home/process";

import { useState, useEffect } from "react";
import PartnerShip from "./shared/home/partnership";

function Home() {
  const enterpriseData = [
    {
      title: "Deploy Generative AI applications that provide immediate value",
      description:
        "OneByZero has significant experience in exploiting generative AI for enterprise search, natural language queries, question-answering, summarization, personalization, content generation and chatbot use-cases.",
      img: "images/home/enterprise/enterprise-1.webp",
      img_mob: "images/home/enterprise/enterprise-1-mob.webp",
    },
    {
      title: "Enhance customer experience across all channels",
      description:
        "OneByZero has a variety of assets and experience around personalization, next-best action and omni-channel messaging use-cases that can drive customer acquisition, engagement and retention. ",
      img: "images/home/enterprise/enterprise-2.webp",
      img_mob: "images/home/enterprise/enterprise-2-mob.webp",
    },
    {
      title: "Lay a modern data foundation to power AI/ML use-cases",
      description:
        "OneByZero will help you deploy a modern data stack, with specific focus on data quality, governance, security, performance and flexibility, that can be the foundation for BI, ML and AI use-cases across the enterprise.",
      img: "images/home/enterprise/enterprise-3.webp",
      img_mob: "images/home/enterprise/enterprise-3-mob.webp",
    },
  ];
  const carouselData1 = [
    {
      img: "/images/home/solution/kuwago.webp",
      img_mob: "/images/home/solution/kuwago_mob.webp",
    },
    {
      img: "/images/home/solution/chattr.webp",
      img_mob: "/images/home/solution/chattr_mob.webp",
    },
    {
      img: "/images/home/solution/hoots.webp",
      img_mob: "/images/home/solution/hoots_mob.webp",
    },
    {
      img: "/images/home/solution/perch.webp",
      img_mob: "/images/home/solution/perch_mob.webp",
    },
    {
      img: "/images/home/solution/pique.webp",
      img_mob: "/images/home/solution/pique_mob.webp",
    },
  ];

  const carouselData2 = [
    {
      img: "/images/home/outcomes/generative-ai.webp",
      img_mob: "/images/home/outcomes/generative-ai-mob.webp",
    },
    {
      img: "/images/home/outcomes/machine-learning.webp",
      img_mob: "/images/home/outcomes/machine-learning-mob.webp",
    },
    {
      img: "/images/home/outcomes/data-platform.webp",
      img_mob: "/images/home/outcomes/data-platform-mob.webp",
    },
    {
      img: "/images/home/outcomes/digital-experience.webp",
      img_mob: "/images/home/outcomes/digital-experience-mob.webp",
    },
  ];

  const [screenSize, setScreenSize] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenSize(window.innerWidth);
    };

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const Layout = ({ children, backgroundColor }) => {
    return screenSize <= 768 ? (
      <MobileLayout backgroundColor={backgroundColor}>{children}</MobileLayout>
    ) : (
      <DesktopLayout backgroundColor={backgroundColor}>
        {children}
      </DesktopLayout>
    );
  };

  return (
    <div>
      <HeroBackground backgroundColor="" optional="home">
        <div>
          <Header />
          <h1 className="text-[49px] sm:text-[64px] text-start sm:text-left text-[#212335] RoobertMedium tracking-[-0.98px] sm:tracking-[-1.8px] leading-[105%] mix-blend-multiply sm:leading-[100%] ml-[6%] mt-[306px] sm:mt-[408px] w-[90%] sm:w-[70%]  ">
            Making AI work for Enterprises
          </h1>
          <h2 className=" mt-[18px] ml-[6%] text-[24px] md:text-[35px] leading-[120%] text-[#212335] RoobertRegular w-[90%] sm:w-[40%] mb-[100px]">
            Unleash your data, unchain your growth, unlock new opportunities
          </h2>
          <div>&nbsp;</div>
        </div>
      </HeroBackground>

      <Layout>
        <div>
          {/* TextContainer */}

          {/* <div>
            <div>&nbsp;</div>
            <div className="w-[88%] md:w-[80%] md:mt-[66px] mb-[40px] md:mb-[126px] flex mx-auto text-[#1E1E1E] ">
              <div className="mt-[33px] md:mt-[67px] flex flex-col md:justify-between md:flex-row  RoobertRegular gap-[98px] md:gap-2 text-[18px] font-normal leading-[120%]">
                <div className="w-[90%] md:w-[41%] ">
                  OneByZero is a consulting firm that blends strategy,
                  technology, data-powered insights and iterative decision
                  optimization. We deliver unparalleled value, innovation and
                  domain expertise. With our special focus on the Asia-Pacific
                  region and a super-talented local and global team - join us on
                  the journey to infinity and beyond.
                </div>
                <div className="w-[90%] md:w-[29%]">
                  We have deep expertise in data platforms, machine learning,
                  and artificial intelligence, underpinned by our distinctive
                  approach to experimentation and exploitation. We help you
                  navigate buzzwords like Generative AI, Modern Data Stack and
                  Hyper Personalization, and deliver tailored solutions.
                </div>
                <div className="md:w-[21%] ml-5">
                  <ul className=" list-item list-disc">
                    <li>
                      Deep expertise in Data Platforms, Machine Learning &
                      Artificial Intelligence
                    </li>
                    <li>
                      Cut through the Generative AI chatter and make it work for
                      you
                    </li>
                    <li>Unique experimentation & exploitation philosophy</li>
                  </ul>
                </div>
              </div>
            </div>

            <div>&nbsp;</div>
          </div> */}

          <TextContainer
            text1="OneByZero is a specialist Machine Learning and Artificial 
            Intelligence services and solutions company. We are unrivalled 
            experts in Machine Learning and Artificial Intelligence research, 
            development and implementation at scale. We excel in running experiments 
            to deploying state-of-the-art models and AI-powered applications for 
            businesses across the Asia-Pacific Region."
            text2=" We help you navigate Generative AI, Machine Learning, Martech 
            and Hyper personalization and Modern Data Platforms to deliver tailored 
            solutions which can help your business reinvent itself. Machine Learning 
            is no longer just a novelty, but a necessity in the modern business world 
            - unlock the power of Artificial Intelligence and make it work for you 
            and your business."
            optional="home"
          />

          {/* TextContainer */}

          {/* HeadingSubHeadingContainer */}

          {/* <div>
            <div className="w-[88%] sm:w-[80%] mx-auto  sm:mt-[65px] mb-[70px] text-[#000]">
              <div className="w-full sm:w-[70%] text-[35px] sm:text-[48px] RoobertMedium leading-[120%]">
                Solutions & services in Generative AI, ML, Data & Customer
                Experience
              </div>
              <div className="w-full sm:w-[58%] mt-6 RoobertRegular text-[18px] leading-[120%]">
                Take advantage of your data, use AI to innovate and scale, and
                better engage with your customers.
              </div>

              <a href="/contact-us">
                <button className="flex py-[10px] group focus:bg-black focus:text-[#FFF] px-4 rounded-[8px] mt-6 sm:mt-12 justify-center items-center">
                  <div className="RoobertRegular underline underline-offset-[5px] group-focus:no-underline hover:decoration-[3px] items-center">
                    Contact Sales
                  </div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="17"
                    height="17"
                    viewBox="0 0 17 17"
                    fill="none"
                    className="ml-[10px] group-focus:hidden"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M4.1816 12.8204C3.98634 12.6251 3.98634 12.3085 4.1816 12.1133L11.8281 4.4668L6.80182 4.4668C6.52568 4.4668 6.30182 4.24294 6.30182 3.9668C6.30182 3.69065 6.52568 3.4668 6.80182 3.4668L13.0352 3.4668C13.1678 3.4668 13.2949 3.51948 13.3887 3.61324C13.4825 3.70701 13.5352 3.83419 13.5352 3.9668L13.5352 10.2001C13.5352 10.4763 13.3113 10.7001 13.0352 10.7001C12.759 10.7001 12.5352 10.4763 12.5352 10.2001V5.17391L4.88871 12.8204C4.69345 13.0156 4.37687 13.0156 4.1816 12.8204Z"
                      fill="black"
                    />
                  </svg>

                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="17"
                    height="17"
                    viewBox="0 0 17 17"
                    fill="none"
                    className="hidden group-focus:block ml-[10px]"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M4.1816 12.8204C3.98634 12.6251 3.98634 12.3085 4.1816 12.1133L11.8281 4.4668L6.80182 4.4668C6.52568 4.4668 6.30182 4.24294 6.30182 3.9668C6.30182 3.69065 6.52568 3.4668 6.80182 3.4668L13.0352 3.4668C13.1678 3.4668 13.2949 3.51948 13.3887 3.61324C13.4825 3.70701 13.5352 3.83419 13.5352 3.9668L13.5352 10.2001C13.5352 10.4763 13.3113 10.7001 13.0352 10.7001C12.759 10.7001 12.5352 10.4763 12.5352 10.2001V5.17391L4.88871 12.8204C4.69345 13.0156 4.37687 13.0156 4.1816 12.8204Z"
                      fill="white"
                    />
                  </svg>
                </button>
              </a>
            </div>

            <div>&nbsp;</div>
          </div> */}

          {/* HeadingSubHeadingContainer */}

          <div className="flex flex-col gap-[94px] md:gap-[32px] mb-[94px] sm:mb-[32px] text-[#000]">
            <TextHorizontalCarousel
              title={"Day 1 value through pre-built solutions"}
              description={
                "Solutions in the spaces of enterprise search, question-answering & analytics, customer data platforms, and customer experience, messaging and chatbots"
              }
              data={carouselData1}
            />
            <TextHorizontalCarousel
              title={"Delivering measurable business outcomes"}
              description={
                " We take a metrics-driven approach in all our consulting services engagements, across various kinds of AI, Data, Martech and Customer engagement projects"
              }
              data={carouselData2}
            />

            <div className="w-[88%] lg:w-[80%] mx-auto">
              <div className="p-[24px] lg:p-[33px] border-[1px] border-[#E7E7E7] flex-col lg:flex-row flex gap-9 bg-white rounded-[20px]">
                <div className="flex items-center w-full lg:w-[60%]">
                  <div className=" lg:px-[28px]">
                    <h2 className="text-[35px] lg:text-[48px] RoobertMedium leading-[100%] RoobertMedium">
                      Iterative Experimentation and Agile Mindset
                    </h2>
                    <div className="w-[100%] lg:w-[75%] mt-9 RoobertRegular text-[18px] leading-[120%] RoobertRegular">
                      A key aspect of any statistical AI/ML project is it’s
                      inherent exploratory and non-deterministic nature, i.e.,
                      it is rarely a straight path from project definition to
                      execution. At OneByZero, we are intimately aware of this,
                      and hence approach every project with an iterative
                      experimentation and agile mindset that allows us to keep
                      adding value over the course of the project. We know how
                      to balance research & experimentation with
                      operationalization to meet desired project metrics.
                    </div>
                  </div>
                </div>
                <div className="w-full lg:w-[40%] lg:px-[26px] flex items-center">
                  <img
                    src="images/home/iterative.webp"
                    alt="Iterative"
                    className="hidden md:block"
                  />
                  <img
                    src="images/home/iterative_mob.webp"
                    alt="Iterative"
                    className="md:hidden"
                  />
                </div>
              </div>
            </div>
          </div>

          <PartnerShip />

          {/* Measurable business outcomes */}
          <div>&nbsp;</div>
        </div>
      </Layout>

      <Layout backgroundColor="black">
        <Enterprise data={enterpriseData} />
      </Layout>

      {/* <Layout>
        <div>
          <div>&nbsp;</div>
          <div className="w-[88%] sm:w-[61%] mt-[76px] sm:mt-[126px] ml-[6%] sm:ml-[10%] text-[#0D0E12]">
            <div className="text-[42px] sm:text-[48px] RoobertMedium leading-[100%] sm:leading-[120%]">
              Let’s talk about how we can supercharge your business.
            </div>

            <a href="/contact-us">
              <button
                className={` flex py-[10px] px-4 bg-[black] hover:bg-[#333333] mt-6 sm:mt-12  rounded-[8px] items-center justify-center`}
              >
                <div className="text-[16px] RoobertRegular text-[#FFF]">
                  Contact Sales
                </div>

                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="17"
                  height="17"
                  viewBox="0 0 17 17"
                  fill="none"
                  className=" ml-[10px]"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M4.1816 12.8204C3.98634 12.6251 3.98634 12.3085 4.1816 12.1133L11.8281 4.4668L6.80182 4.4668C6.52568 4.4668 6.30182 4.24294 6.30182 3.9668C6.30182 3.69065 6.52568 3.4668 6.80182 3.4668L13.0352 3.4668C13.1678 3.4668 13.2949 3.51948 13.3887 3.61324C13.4825 3.70701 13.5352 3.83419 13.5352 3.9668L13.5352 10.2001C13.5352 10.4763 13.3113 10.7001 13.0352 10.7001C12.759 10.7001 12.5352 10.4763 12.5352 10.2001V5.17391L4.88871 12.8204C4.69345 13.0156 4.37687 13.0156 4.1816 12.8204Z"
                    fill="white"
                  />
                </svg>
              </button>
            </a>
          </div>

          <div className="mb-[66px] mt-[117px]">
            <Testimonail />
          </div>
          <div>&nbsp;</div>
        </div>
      </Layout> */}

      <Layout>
        <Process />
      </Layout>

      <Layout backgroundColor="black">
        <Contact
          title={
            "See how Generative AI can revolutionize your business, and improve customer engagement and employee productivity"
          }
          type="service"
        />
        <Footer />
      </Layout>
    </div>
  );
}

export default Home;
