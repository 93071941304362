import TextContainer from "./shared/textContainer";
import HeadingSubHeadingContainer from "./shared/headingSubHeadingContainer";
import Slider from "./shared/slider";
import About from "./shared/about";
import WhitePaper from "./shared/whitepaper";
import Footer from "./shared/footer";
import Contact from "./shared/contact";

import MobileLayout from "./shared/mobile_layout";
import { useState, useEffect } from "react";
import DesktopLayout from "./shared/desktop_layout";
import MobileHero from "./shared/mobile_hero";
import DesktopHero from "./shared/desktop_hero";
import AboutMobile from "./shared/about_mobile";

function Kuwago() {
  const carouselData = [
    {
      title: "Improve Customer Service",
      description:
        "Reduce number of support personnel needed by allowing customers as well as employees search for answers to common questions",
    },
    {
      title: "Reduce your BI Load",
      description:
        "The customer value management department of a telecom company was able to allow access to 100s of employees to a massive multi-PB dataset and reduce the load on a small BI team for ad-hoc data requests. ",
    },
    {
      title: "Improve Productivity",
      description:
        "Improve productivity of developers, project managers and product managers by allowing rapid search across code, documentation and design docs ",
    },
  ];
  const data = [
    {
      img: "/images/kuwago/employees.webp",
      img_mob: "/images/kuwago/employees_mob.webp",
      title: "EMPLOYEE FAQS",
      description:
        "Provide quick answers to frequently asked questions from various sources.",
    },
    {
      img: "/images/kuwago/self-service.webp",
      img_mob: "/images/kuwago/self-service_mob.webp",
      title: "self-service data access for Multiple roles",
      description:
        "Empower all employees - developers, product managers, project managers, data analysts, customer support , marketing, sales  - to access data from multiple enterprise sources.",
    },
    {
      img: "/images/kuwago/nlp.webp",
      img_mob: "/images/kuwago/nlp_mob.webp",
      title: "SPECIALIZED NLP FOR SQL GENERATION FOR BI USE-CASES",
      description:
        "Generate SQL for analytical queries on structured datasets. Present results using a blend of charts, natural language narratives and even data stories. Reduce the load on specialized data teams or BI teams for ad-hoc data requests.",
    },
    {
      img: "/images/kuwago/customer.webp",
      img_mob: "/images/kuwago/customer_mob.webp",
      title: "CUSTOMER SUPPORT",
      description:
        "Search for and generate answers to customer questions or issues from internal sources. Can also support agents as they interact with customers.",
    },
  ];

  const aboutData = [
    {
      img: "images/kuwago/About/mobile/img1.png",
      img_web: "images/kuwago/About/web/ai.webp",
      title: "AI-Powered Search & QA",
      description:
        "Powerful search and question-answering framework for unstructured and semi-structured enterprise datasets. Also incorporates a user-feedback loop and MLOps framework that refines model training, ranking, search optimization, LLM fine-tuning  and improve SQL code generation",
    },

    {
      img: "images/kuwago/About/mobile/img3.png",

      img_web: "images/kuwago/About/web/efficient.webp",
      title: "Efficient Indexing",
      description:
        "Data crawling and ingestion infrastructure to collect and index data from multiple enterprise sources",
    },

    {
      img: "images/kuwago/About/mobile/img2.png",
      img_web: "images/kuwago/About/web/intelligent.webp",
      title: "Intelligent Query Processing",
      description:
        "Seamlessly handle user queries by intelligently matching them with relevant documents, then ranking the results for optimal relevance. For analytical queries, it generates SQL on DBs and generates charts and data stories.",
    },

    {
      img: "images/kuwago/About/mobile/img4.png",
      img_web: "images/kuwago/About/web/secure.webp",
      title: "Secure Access",
      description:
        "Role-based security mechanisms to ensure data access control.",
    },
  ];

  const [screenSize, setScreenSize] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenSize(window.innerWidth);
    };

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const Layout = ({ children, backgroundColor }) => {
    return screenSize <= 768 ? (
      <MobileLayout backgroundColor={backgroundColor}>{children}</MobileLayout>
    ) : (
      <DesktopLayout backgroundColor={backgroundColor}>
        {children}
      </DesktopLayout>
    );
  };

  return (
    <div>
      <DesktopHero
        heroImage={"images/kuwago/hero.webp"}
        heading="Enterprise search & question-answering that works!"
        title={"KUWAGO"}
        backgroundColor="red-pink"
      />

      <MobileHero
        heading={"Enterprise search & question-answering that works!"}
        title={"KUWAGO"}
        backgroundColor="red-pink"
      />

      <Layout>
        <div className=" md:hidden w-[88%] mx-auto">
          <div>&nbsp;</div>
          <img
            src="/images/kuwago/kuwago_hero_mob.webp"
            alt="frame1"
            className=" mt-[40px]"
          />
        </div>
      </Layout>

      <Layout>
        <div>
          <TextContainer
            text1="Kuwago is a powerful search, query and question-answering framework over unstructured, semi-structured and structured enterprise datasets"
            text2="Experience a Generative AI-powered natural language query interface, customizable for any domain, enterprise, or department."
          />
          <HeadingSubHeadingContainer
            heading="Take your data to the next level with Kuwago"
            subHeading="Improve productivity and expedite decision making. Facilitate searches, questions and queries on a variety of enterprise sources including file repositories, websites, data lakes, data warehouses, databases, wikis, chat systems, code repositories, ticketing systems, CRM systems and content management systems. "
          />
          <div>
            <Slider data={data} swiperHeight={432} />
          </div>
        </div>
      </Layout>

      <Layout backgroundColor="black">
        <About
          title={"Why Kuwago?"}
          description={
            "Modern enterprises use a diverse array of tools and applications leading to data scattering across these platforms. Kuwago uses generative AI to address this issue by providing configurable natural language search, question-answering and summarization features across multiple enterprise datasets consistently."
          }
          img1={"/images/kuwago/powered-search.webp"}
          img2={"/images/kuwago/indexing.webp"}
          img3={"/images/kuwago/processing.webp"}
          img4={"/images/kuwago/secure.webp"}
          data={aboutData}
        />
        <AboutMobile
          title={"Why Kuwago?"}
          description={
            "Modern enterprises use a diverse array of tools and applications leading to data scattering across these platforms. Kuwago uses generative AI to address this issue by providing configurable natural language search, question-answering and summarization features across multiple enterprise datasets consistently."
          }
          data={aboutData}
        />
      </Layout>

      <Layout>
        <WhitePaper
          heading={"Meet your business goals with Kuwago"}
          subHeading={"Welcome to the future of business."}
          title={"Increase Impact with Kuwago"}
          description={
            "With best in class tools to help you streamline customer service, see how Kuwago can help your organization make business communication more efficient."
          }
          carouselData={carouselData}
        />
      </Layout>

      <Layout backgroundColor="black">
        <Contact
          title={
            "See how Kuwago can simplify access to data for your enterprise and customers"
          }
          type="solution"
        />
        <Footer />
      </Layout>
    </div>
  );
}

export default Kuwago;
