import TextContainer from "./shared/textContainer";
import HeadingSubHeadingContainer from "./shared/headingSubHeadingContainer";
import Slider from "./shared/slider";
import About from "./shared/about";
import WhitePaper from "./shared/whitepaper";
import Contact from "./shared/contact";
import Footer from "./shared/footer";
import Header from "./shared/header";
import MobileLayout from "./shared/mobile_layout";
import { useState, useEffect } from "react";
import MobileHero from "./shared/mobile_hero";
import DesktopLayout from "./shared/desktop_layout";
import HeroBackground from "./shared/hero_background";
import DesktopHero from "./shared/desktop_hero";
import AboutMobile from "./shared/about_mobile";

function Chattr() {
  const data = [
    {
      img: "/images/chattr/Carsouel/bots.webp",
      img_mob: "/images/chattr/Carsouel/bots_mob.webp",
      title: "Support Bots",
      description:
        "Build and deploy chatbots for customer support that can handle FAQs, provide personalized advice and recommendations, and take on different tones depending on customer context. ",
    },
    {
      img: "/images/chattr/Carsouel/variety.webp",
      img_mob: "/images/chattr/Carsouel/variety_mob.webp",
      title: "tap into a variety of enterprise knowledge bases",
      description:
        "Enable self-service options for handling frequently asked questions that can provide answers to customer questions from a variety of enterprise knowledge bases, datasets and other assets. ",
    },
    {
      img: "/images/chattr/Carsouel/offers.webp",
      img_mob: "/images/chattr/Carsouel/offers_mob.webp",
      title: "Personalized recommendations & offers",
      description:
        "Identify users reliably, and provide relevant recommendations and offers, aided by personalized content generation powered by LLMs. ",
    },
  ];

  const carouselData = [
    {
      title: "Customer Loyalty",
      description:
        "Improved customer loyalty as evidenced by better CSAT scores.",
    },
    {
      title: "Increased transaction volume",
      description:
        "We need to write a little more about how we think we managed to do this here.",
    },
    {
      title: "Increased transaction volume",
      description:
        "We need to write a little more about how we think we managed to do this here.",
    },
  ];

  const WhitePapercardData = [
    {
      img: "images/analytics-and-ml/90x.webp",
      svg: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="32"
          height="33"
          viewBox="0 0 32 33"
          fill="none"
        >
          <g opacity="0.5">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M1.06667 0.0449219C0.477563 0.0449219 0 0.522485 0 1.11159V30.9783C0 31.5674 0.477563 32.0449 1.06667 32.0449H9.6C10.1891 32.0449 10.6667 31.5674 10.6667 30.9783V10.7116H30.9333C31.5224 10.7116 32 10.234 32 9.64492V1.11159C32 0.522485 31.5224 0.0449219 30.9333 0.0449219H1.06667ZM2.13334 8.73836L2.13333 2.17826H8.69338C8.69338 2.17826 8.69338 2.17826 8.69338 2.17826L8.69345 8.7384L2.13334 8.73836ZM2.13333 10.5517L2.13333 29.9116H8.53333V27.6183H5.86672C5.36598 27.6183 4.96005 27.2124 4.96005 26.7117C4.96005 26.2109 5.36598 25.805 5.86672 25.805H8.53333V23.3517H4.80005C4.29931 23.3517 3.89339 22.9457 3.89339 22.445C3.89339 21.9442 4.29931 21.5383 4.80005 21.5383H8.53333V19.085L5.86671 19.085C5.36597 19.085 4.96005 18.679 4.96005 18.1783C4.96006 17.6775 5.36599 17.2716 5.86673 17.2716L8.53333 17.2717V14.8183L5.86665 14.8183C5.36592 14.8183 4.95999 14.4123 4.96 13.9116C4.96001 13.4108 5.36594 13.0049 5.86668 13.0049L8.53333 13.005V10.5517L2.13333 10.5517ZM10.5068 8.57826H12.96V5.91172C12.96 5.41098 13.3659 5.00505 13.8667 5.00505C14.3674 5.00505 14.7733 5.41098 14.7733 5.91172V8.57826H17.2267V5.91169C17.2267 5.41096 17.6326 5.00503 18.1334 5.00503C18.6341 5.00503 19.04 5.41096 19.04 5.91169V8.57826H21.4935V4.84503C21.4935 4.34429 21.8994 3.93836 22.4001 3.93836C22.9009 3.93836 23.3068 4.34429 23.3068 4.84503V8.57826H25.7602V5.91169C25.7602 5.41096 26.1661 5.00503 26.6668 5.00503C27.1676 5.00503 27.5735 5.41096 27.5735 5.91169V8.57826H29.8667V2.17826H10.5067L10.5068 8.57826Z"
              fill="black"
            />
          </g>
        </svg>
      ),
      title: "Increase Response Accuracy",
      description:
        "Chatter achieved a 90% increase in response accuracy while engaging in over 15,000 conversations per month.",
    },
  ];

  const aboutData = [
    {
      img: "images/chattr/About/mobile/img1.webp",
      img_web: "images/chattr/About/web/llm.webp",
      title: "LLM Powered Engine",
      description:
        "Conversational AI components, including an LLM-driven FAQ engine, intent recognition, spell correction, named entity recognition, question-answering, summarization, smalltalk response, and memory maintenance",
    },

    {
      img: "images/chattr/About/mobile/img2.webp",
      img_web: "images/chattr/About/web/ready.webp",
      title: "Ready, set, done.",
      description:
        "Deployment framework for easy deployment on dev, staging, and production environments. Includes supporting infrastructure for collecting user feedback, finetuning the NLP and measuring end-to-end performance. ",
    },

    {
      img: "images/chattr/About/mobile/img3.webp",
      img_web: "images/chattr/About/web/flow.webp",
      title: "Flow Designer",
      description:
        "UI-based flow designer for rapid dialog flow creation and modification",
    },
  ];

  const [screenSize, setScreenSize] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenSize(window.innerWidth);
    };

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const Layout = ({ children, backgroundColor }) => {
    return screenSize <= 768 ? (
      <MobileLayout backgroundColor={backgroundColor}>{children}</MobileLayout>
    ) : (
      <DesktopLayout backgroundColor={backgroundColor}>
        {children}
      </DesktopLayout>
    );
  };

  return (
    <div>
      {/* Hero Container */}

      {/* Hero Container */}

      <DesktopHero
        heroImage={"images/chattr/hero.webp"}
        heading="Generative-AI Powered Chatbot Engine"
        title={"CHATTER"}
        backgroundColor="blue"
      />

      <MobileHero
        heading={"Generative-AI Powered Chatbot Engine"}
        title={"CHATTER"}
        backgroundColor="blue"
      />

      <Layout>
        <div className=" sm:hidden w-[88%] mx-auto">
          <div>&nbsp;</div>
          <img
            src="/images/chattr/chatter_hero_mob.webp"
            alt="frame1"
            className=" mt-[40px]"
          />
        </div>
      </Layout>

      <Layout>
        <div>
          {" "}
          <TextContainer
            text1="Chatter is a chatbot engine that infuses generative AI into different aspects like intent recognition, handling smalltalk in a customized manner, orchestrating between enterprise API endpoints, supporting FAQs, generating personalized content, etc.  "
            text2="Dramatically improve overall usability, the ability to respond to various customer utterances and reduction in time to go live."
          />
          <HeadingSubHeadingContainer
            heading="Take your chatbots to the next level with Chatter"
            subHeading="Chatter includes a set of hand-picked LLMs, a framework for prompt-engineering and fine-tuning, and an agent-based generative AI platform that can revolutionize your chatbot, and allow it to improve precision and recall while responding to customer utterances. "
          />
          <Slider data={data} swiperHeight={464} />
        </div>
      </Layout>

      <Layout backgroundColor="black">
        <About
          title={"Why Chatter?"}
          description={
            "Many chatbots have long suffered from being able to respond to a very limited set of user utterances. With Chatter LLM-powered framework, you can dramatically improve the ability to your chatbot to user inputs, hence driving engagement and satisfaction, while also reducing the need for manual intervention. "
          }
          img1={"/images/chattr/LLM.webp"}
          img2={""}
          img3={"/images/chattr/RSD.webp"}
          img4={"/images/chattr/flow.webp"}
          data={aboutData}
        />
        <AboutMobile
          title={"Why Chatter?"}
          description={
            "Many chatbots have long suffered from being able to respond to a very limited set of user utterances. With Chatter LLM-powered framework, you can dramatically improve the ability to your chatbot to user inputs, hence driving engagement and satisfaction, while also reducing the need for manual intervention."
          }
          data={aboutData}
        />
      </Layout>

      <Layout>
        <WhitePaper
          heading={"Meet your business goals with Chatter"}
          subHeading={"Welcome to the future of business."}
          title={"Go Deeper with Chatter"}
          description={
            "Chatter is the future of enterprise conversations. Take advantage of world class tooling to hold better conversations with your customers today."
          }
          cardData={WhitePapercardData}
          carouselData={carouselData}
        />
      </Layout>

      <Layout backgroundColor="black">
        <Contact
          title={
            "See how Chatter can make your chatbots really worth chatting about. "
          }
          type="solution"
        />
        <Footer />
      </Layout>
    </div>
  );
}

export default Chattr;
