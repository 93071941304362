import HeroBackground from "./hero_background";
import Header from "./header";
function DesktopHero({
  heroImage,
  heading,
  title,
  backgroundColor,
  name = "none",
}) {
  const bottomDistance = {
    "data-platform": 60,
    "analytics-and-ml": 40,
    "digital-experience": 120,
    "generative-ai": 10,
  };

  let heroTitleWidth = 42;

  const titleWidth = {
    "data-platform": 38,
    "analytics-and-ml": 40,
    "digital-experience": 38,
    "generative-ai": 40,
  };

  if (titleWidth[name]) {
    heroTitleWidth = titleWidth[name];
  }

  let imageBottomDistance = 20;

  if (bottomDistance[name]) {
    imageBottomDistance = bottomDistance[name];
  }

  console.log("imageBottomDistance ", imageBottomDistance);

  return (
    <HeroBackground backgroundColor={backgroundColor}>
      <div className=" hidden md:block h-full">
        <Header />

        <div
          className=" relative flex-grow flex justify-between"
          style={{ height: "calc(100% - 16px)" }}
        >
          <div
            className="w-[42%] flex flex-col gap-[13px] ml-[5%] justify-end mb-[143px]"
            style={{ width: `${heroTitleWidth}%` }}
          >
            <div className="tracking-[0.18px] text-[52px] leading-[120%] RoobertLight text-[#0D0E12]">
              {heading}
            </div>
            <div className="flex items-center gap-2">
              <div className="ObviouslyDemoRegular text-[18px] text-[#7E7E7E]">
                ONEBYZERO
              </div>
              <div className="ObviouslyDemoWideMedium text-[18px] leading-[150%] text-[#000]">
                {title}
              </div>
            </div>
          </div>

          <div
            className={` w-[43%] absolute bottom-[${imageBottomDistance}px] right-[5%]`}
            style={{ bottom: `${imageBottomDistance}px` }}
          >
            <img src={heroImage} alt={title} />
          </div>
        </div>
      </div>
    </HeroBackground>
  );
}

export default DesktopHero;
