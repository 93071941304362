function GenText() {
  return (
    <div className="RoobertRegular">
      OnebyZero has deep experience in Generative AI and deep learning. We keep
      ourselves up-to-date on the latest in the world of LLMs, chains, agents,
      platforms and use-cases so that you don’t have to. And we have experience
      in going from simple demos to all the challenges around going live.
      Furthermore, we have our own{" "}
      <strong className="RoobertRegular">Reference Architecture</strong> for
      generative AI applications that relies on a combination of open source and
      proprietary software and LLMs.{" "}
    </div>
  );
}

function Strategy({ title, description, img, dataMobile, dataWeb, optional }) {
  let list = [];
  if (dataMobile !== undefined) {
    list = dataMobile.map((item, index) => {
      return (
        <div
          key={index}
          className="px-[23px] py-4 rounded-[16px]"
          style={{
            background:
              "linear-gradient(217deg, rgba(21, 22, 23, 0.70) 0%, rgba(51, 55, 66, 0.50) 100%)",
          }}
        >
          <div>
            <img src={item.img} alt="strategy" className="w-[20%]" />
          </div>
          <div className="mt-4 text-[#FAF7F7]">
            <div className="text-[24px] tracking-[-0.48px] leading-[120%] RoobertMedium ">
              {item.title}
            </div>
            <div className="mt-3 text-[18px] opacity-80 RoobertRegular leading-[120%]">
              {item.description}
            </div>
          </div>
        </div>
      );
    });
  }

  const webList = [];
  for (let index = 0; index < 2 * Math.floor(dataWeb.length / 2); index += 2) {
    const ele1 = (
      <div
        style={{
          clipPath: "polygon(0 0, 100% 0, 100% 70%, 41% 70%, 41% 100%, 0 100%)",
        }}
        className={`clip-card h-[578px] w-[81%] flex p-6  ${
          index > 0 ? "mt-[20px]" : ""
        }`}
      >
        <div className="w-[40%] pr-6">
          <img src={dataWeb[index].img} className="h-full w-full" />
        </div>
        <div className="w-[60%] px-[13px] text-[#FAF7F7]">
          <div className="w-full xl:w-[357px] mt-[240px]">
            <div className="text-[24px] RoobertMedium leading-[120%] tracking-[-0.48px] ">
              {dataWeb[index].title}
            </div>
            <div className="mt-[18px] RoobertRegular leading-[120%] opacity-80">
              {dataWeb[index].description}
            </div>
          </div>
        </div>
      </div>
    );

    const ele2 = (
      <div
        style={{
          clipPath: "polygon(0 39%, 19% 39%, 19% 0, 100% 0, 100% 100%, 0 100%)",
        }}
        className={`clip-card h-[441px] w-[80%] p-6 ml-[20%] flex relative top-[-147px] mb-[-147px] `}
      >
        <div className="w-[50%] px-[13px] flex items-end text-[#FAF7F7]">
          <div className="w-full xl:w-[342px] ">
            <div className="text-[24px] RoobertMedium leading-[120%] tracking-[-0.48px] ">
              {dataWeb[index + 1].title}
            </div>
            <div className="mt-[18px] RoobertRegular leading-[120%] opacity-80">
              {dataWeb[index + 1].description}
            </div>
          </div>
        </div>

        <div className="w-[40%] pl-[36px] ml-auto">
          <img src={dataWeb[index + 1].img} className="h-full w-full" />
        </div>
      </div>
    );

    webList.push(ele1);
    webList.push(ele2);
  }

  console.log("dataWeb.length % 2", dataWeb.length % 2);
  if (dataWeb.length % 2 === 1) {
    webList.push(
      <div
        className={`clip-card h-[348px] w-[78%] flex p-6 ml-[9%] mt-[20px] `}
      >
        <div className="w-[35%] pr-6">
          <img src={dataWeb.at(-1).img} className="h-full w-full" />
        </div>
        <div className="w-full px-[13px] text-[#FAF7F7] flex items-end">
          <div className="w-full ">
            <div className="text-[24px] RoobertMedium leading-[120%] tracking-[-0.48px] ">
              {dataWeb.at(-1).title}
            </div>
            <div className="mt-[18px] RoobertRegular leading-[120%] opacity-80">
              {dataWeb.at(-1).description}
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <div>&nbsp;</div>
      <div className="mt-[94px] text-[#FFFFFF] sm:ml-[10%] w-[87%] mx-auto sm:mx-9 sm:w-[78%]">
        <div className=" text-[35px] sm:text-[48px] sm:w-[75%] leading-[120%] sm:leading-[100%] RoobertMedium">
          {title}
        </div>
        <div className="mt-[20px] sm:mt-[24px] text-[18px] leading-[120%] RoobertRegular sm:w-[55%]">
          {/* {description} */}
          {optional === "generative-ai" ? (
            <GenText />
          ) : (
            <div className="RoobertRegular">{description}</div>
          )}
        </div>
      </div>

      <div className="mt-[150px] w-[80%] mx-auto hidden md:flex md:flex-col mb-[166px]">
        {webList}
      </div>

      <div className="h-0">&nbsp;</div>

      <div className="sm:hidden">
        <div className="sm:hidden flex flex-col gap-[64px] w-[87%] mx-auto mt-[150px] mb-[129px]">
          {list}
        </div>
        <div>&nbsp;</div>
      </div>
    </div>
  );
}

export default Strategy;
