import HorizontalCarousel from "./horizontal-carousel";

function DownloadWhitepaper({ title, description }) {
  return (
    <div className=" h-fit  mb-[0px] sm:mb-[148px] px-[27px] py-[38px] bg-white rounded-[8px]">
      <div className="text-[8px] sm:text-[16px]">
        <div className="mt-2 text-[18px] leading-[120%] RoobertRegular text-[#000]">
          {description}
        </div>
      </div>
      {/* <button className="w-[75%] md:w-[220px] mt-8 px-3 py-[10px] flex items-center bg-black hover:bg-[#333] rounded-[8px]">
        <div className=" items-center justify-center flex">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
            className="hidden sm:block"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M5 3.40039C4.72386 3.40039 4.5 3.62425 4.5 3.90039V21.1004C4.5 21.3765 4.72386 21.6004 5 21.6004H19C19.2761 21.6004 19.5 21.3765 19.5 21.1004V9.80039H13.6C13.3239 9.80039 13.1 9.57653 13.1 9.30039V3.40039H5ZM14.1 4.1075L18.7929 8.80039H14.1V4.1075ZM3.5 3.90039C3.5 3.07196 4.17157 2.40039 5 2.40039H13.6C13.7326 2.40039 13.8598 2.45307 13.9536 2.54684L20.3536 8.94684C20.4473 9.04061 20.5 9.16778 20.5 9.30039V21.1004C20.5 21.9288 19.8284 22.6004 19 22.6004H5C4.17157 22.6004 3.5 21.9288 3.5 21.1004V3.90039Z"
              fill="white"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M11.9968 12C12.2129 12 12.3881 12.1752 12.3881 12.3913V18.913C12.3881 19.1292 12.2129 19.3043 11.9968 19.3043C11.7807 19.3043 11.6055 19.1292 11.6055 18.913V12.3913C11.6055 12.1752 11.7807 12 11.9968 12Z"
              fill="white"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M9.11461 16.4427C9.26742 16.2899 9.51518 16.2899 9.668 16.4427L12 18.7747L14.332 16.4427C14.4848 16.2899 14.7326 16.2899 14.8854 16.4427C15.0382 16.5955 15.0382 16.8433 14.8854 16.9961L12.2767 19.6048C12.1239 19.7576 11.8761 19.7576 11.7233 19.6048L9.11461 16.9961C8.9618 16.8433 8.9618 16.5955 9.11461 16.4427Z"
              fill="white"
            />
          </svg>

          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
            className="sm:hidden"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M5 3.46094C4.72386 3.46094 4.5 3.68479 4.5 3.96094V21.1609C4.5 21.4371 4.72386 21.6609 5 21.6609H19C19.2761 21.6609 19.5 21.4371 19.5 21.1609V9.86094H13.6C13.3239 9.86094 13.1 9.63708 13.1 9.36094V3.46094H5ZM14.1 4.16804L18.7929 8.86094H14.1V4.16804ZM3.5 3.96094C3.5 3.13251 4.17157 2.46094 5 2.46094H13.6C13.7326 2.46094 13.8598 2.51362 13.9536 2.60738L20.3536 9.00738C20.4473 9.10115 20.5 9.22833 20.5 9.36094V21.1609C20.5 21.9894 19.8284 22.6609 19 22.6609H5C4.17157 22.6609 3.5 21.9894 3.5 21.1609V3.96094Z"
              fill="white"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M12.0007 12.0605C12.2168 12.0605 12.392 12.2357 12.392 12.4519V18.9736C12.392 19.1897 12.2168 19.3649 12.0007 19.3649C11.7846 19.3649 11.6094 19.1897 11.6094 18.9736V12.4519C11.6094 12.2357 11.7846 12.0605 12.0007 12.0605Z"
              fill="white"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M9.11461 16.5033C9.26742 16.3505 9.51518 16.3505 9.668 16.5033L12 18.8353L14.332 16.5033C14.4848 16.3505 14.7326 16.3505 14.8854 16.5033C15.0382 16.6561 15.0382 16.9039 14.8854 17.0567L12.2767 19.6654C12.1239 19.8182 11.8761 19.8182 11.7233 19.6654L9.11461 17.0567C8.9618 16.9039 8.9618 16.6561 9.11461 16.5033Z"
              fill="white"
            />
          </svg>
        </div>
        <p className="text-white ml-2 text-[16px] RoobertRegular sm:w-full items-center">
          Download whitepaper
        </p>
      </button> */}
    </div>
  );
}

function WhitePaper({
  heading,
  subHeading,
  title,
  description,
  carouselData = [],
  cardData = [],
}) {
  const cardList = cardData.map((item, index) => {
    return (
      <div
        key={index}
        className="flex sm:flex-row flex-col gap-8 items-center justify-center mx-auto w-[80%] sm:w-full"
      >
        <div className="p-[10px] bg-white rounded-[6.675px] sm:w-[60%] h-fit">
          <img src={item.img} alt="nps-score" />
        </div>

        <div className="flex sm:items-center sm:justify-center sm:w-[68%]">
          {" "}
          <div className="">
            <div>{item.svg}</div>
            <div className="mt-[10px]  RoobertMedium leading-[120%] text-[24px] text-[#000]">
              {item.title}
            </div>
            <div className="mt-[10px] text-[18px] leading-[120%] RoobertRegular text-[#000]">
              {item.description}
            </div>
          </div>
        </div>
      </div>
    );
  });

  return (
    <div className="whitepaper">
      <div>&nbsp;</div>
      <div className="w-[86%] sm:w-[80%] mx-auto mt-[66px] sm:mt-[71px]">
        <div className="text-[35px] sm:w-[75%] sm:text-[48px] RoobertMedium leading-[120%] text-[#0D0E12]">
          {heading}
        </div>
        {/* <div className="text-[18px] RoobertRegular leading-[120%] text-black mt-6">
          {subHeading}
        </div> */}
      </div>

      <div className="flex sm:flex-row flex-col mb-[40px] w-[87%] sm:w-[80.5%] mx-auto sm:mb-[101px] mt-[64px] sm:mt-[131px]">
        <div className="hidden sm:block w-[43%]">
          <DownloadWhitepaper title={title} description={description} />
        </div>
        {/* <DownloadWhitepaper title={title} description={description} /> */}

        <div className="sm:ml-[9%] sm:w-[48%]">
          <div className="grid grid-cols-1 gap-[58px] items-center">
            {cardList}
          </div>
          <div
            className={`${cardData.length > 0 ? "mt-[58px]" : ""} ${
              carouselData.length === 0 ? "hidden" : ""
            }`}
          >
            <HorizontalCarousel data={carouselData} />
          </div>
        </div>

        <div className="sm:hidden mt-[64px]">
          <DownloadWhitepaper title={title} description={description} />
        </div>
      </div>

      <div>&nbsp;</div>
    </div>
  );
}

export default WhitePaper;
